import React from 'react'

import ProducerRatingFilterComponent from './components/producer-rating-filter-component'
import {Tag} from './components/producer-view'
import ProductTagFilterComponent from './components/product-tag-filter-component'
import {Field} from './components/product-view'
import {ProducerTagSelect} from './components/tag'
import {ProductTagSelect} from './components/tag'


export const productFields = [
    {value: 'product.producer_id', operator: 'IS NULL'},
    {value: 'product.name'},
    {value: 'producer.name'},
    {value: 'producer_tag.tag', operator: '=', FilterComponent: ({item, onUpdate, className}) => {
        return <ProducerTagSelect
            value={item.operand}
            onUpdate={value => onUpdate({operand: value, operator: '='})}
            extraClassName={className}
        />
    }},
    {
        value: 'product_tag.tag',
        operator: '=',
        FilterComponent: ({item, onUpdate, className}) => {
            return <ProductTagSelect
                value={item.operand}
                onUpdate={value => onUpdate({operand: value, operator: '='})}
                extraClassName={className}
            />
        }
    },
    {
        value: 'product_tag',
        sortable: false,
        fields: [
            {
                value: 'product_tag.tag',
                operator: '=',
                FilterComponent: ({item, onUpdate, className}) => {
                    return <ProductTagSelect
                        value={item.operand}
                        onUpdate={value => onUpdate({operand: value, operator: '='})}
                        extraClassName={className}
                    />
                },
            },
            {value: 'product_tag.value'},
        ],
    },
    {
        value: 'product_rating',
        fields: [
            {value: 'product_rating.source', operator: '='},
            {value: 'product_rating.normalized_rating', operator: '>='},
            {value: 'product_rating.canonical_rating', operator: '='},
            {value: 'product_rating.timestamp', operator: '>='},
        ],
    },
    {value: 'product_rating.normalized_rating', operator: '>='},
    {value: 'product_rating.source'},
    {value: 'product_rating.canonical_rating', operator: '='},
    {value: 'product_rating.timestamp'},
    {value: 'product.best_after'},
    {value: 'product.best_at'},
    {value: 'product.best_before'},
    {value: 'product._created_at'},
    {value: 'product._updated_at'},
    {value: 'product.total_normalized_rating', operator: '>='},
    {value: 'product.readiness', filterable: false},
    {value: 'product.number_of_resources', operator: '>='},
]

export const producerFields = [
    {value: 'producer.rating', FilterComponent: ProducerRatingFilterComponent},
    {value: 'producer.name'},
    {value: 'producer.address', operator: 'LIKE'},
    {value: 'producer.fts'},
    {
        value: 'producer_tag.tag',
        operator: '=',
        FilterComponent: ({item, onUpdate, className}) => {
            return <ProducerTagSelect
                value={item.operand}
                onUpdate={value => onUpdate({operand: value, operator: '='})}
                extraClassName={className}
            />
        },
        SortComponent: ({item, onUpdate, className}) => {
            return <><ProducerTagSelect
                value={item.operand}
                onUpdate={value => onUpdate({operand: value})}
                extraClassName={className}
            />
                <input type="number"
                    value={item.value}
                    onChange={e => onUpdate({value: e.target.value})}
                />
            </>
        }
    },
    {
        value: 'producer_tag',
        sortable: false,
        fields: [
            {
                value: 'producer_tag.tag',
                operator: '=',
                FilterComponent: ({item, onUpdate, className}) => {
                    return <ProducerTagSelect
                        value={item.operand}
                        onUpdate={value => onUpdate({operand: value, operator: '='})}
                        extraClassName={className}
                    />
                },
            },
            {value: 'producer_tag.value'},
        ],
    },
    {value: 'producer._created_at'},
    {value: 'producer._updated_at'},
    {value: 'product.name'},
    {value: 'product_rating.normalized_rating', operator: '>='},
    {value: 'product_rating.source'},
    {value: 'product_rating.canonical_rating'},
    {value: 'product_rating.timestamp'},
    {
        value: 'product_rating',
        sortable: false,
        fields: [
            {value: 'product_rating.source', operator: '='},
            {value: 'product_rating.normalized_rating', operator: '>='},
            {value: 'product_rating.canonical_rating', operator: '='},
            {value: 'product_rating.timestamp', operator: '>='},
        ],
    },
    {
        value: 'product_tag.tag',
        sortable: false,
    },
    {
        value: 'product_tag',
        sortable: false,
        fields: [
            {
                value: 'product_tag.tag',
                operator: '=',
                FilterComponent: ({item, onUpdate, className}) => {
                    return <ProductTagSelect
                        value={item.operand}
                        onUpdate={value => onUpdate({operand: value, operator: '='})}
                        extraClassName={className}
                    />
                },
            },
            {value: 'product_tag.value'},
        ],
    },
    {value: 'product._created_at'},
    {value: 'product._updated_at'},
    {value: 'producer.google_place_id', operator: 'IS NULL'},
]


export const fields = {
    producer: producerFields,
    product: productFields,
}
