import React from 'react'

import useCloseOnClickOutside from '../hooks/use-close-on-click-outside'
import bem from '../utils/bem'
import Button from './button'
import CloseIcon from './close-icon'
import get from './get'
import {align} from './popup-menu'
import {useAlignOnScroll} from './popup-menu'
import Portal from './portal'
import './popup-select.scss'


export function QuickSelect({options, ...props}) {
    const [query, setQuery] = React.useState('')

   const matching = React.useMemo(() => {
       if (!query || query.length === 0) {return options}
       return options.filter(o => {
           if (o.key && !o.value) {return true}
           let value = o.value || o
            if (!value) {
                return false
            }
            if (!query) {
                return true
            }
            if (value.value !== undefined) {
                value = value.value
            }
           return value.toLowerCase().indexOf(query.toLowerCase()) !== -1
       })
   }, [options, query])

    return <Select
        {...props}
        options={matching}
        query={query}
        setQuery={setQuery}
    />
}


export function Select({
    value,
    valueOption,
    onUpdate,
    options,
    ValueComponent,
    OptionComponent,
    alignOptions,
    extraClassName,
    onCreate,
    placeholder,
    query,
    setQuery,
    listExtraClassName,
    nullable,
    filterable,
    style,
}) {
    const className = bem('popup-select')

    const [open, setOpen] = React.useState(false)
    const ref = React.useRef()
    const sref = React.useRef()
    const iref = React.useRef()

    const valueOption_ = React.useMemo(() => {
        if (valueOption) {return valueOption}
        if (!options) {return []}
        return options.filter(o => o === value || o.value === value)[0]
    }, [valueOption, options, value])

    const queryOption = React.useMemo(() => {
        if (!options) {return []}
        return options.filter(o => o === query || o.value === query)[0]
    }, [options, query])

    function focusQuery() {
        if (iref.current) {
            iref.current.focus()
        }
        else {
            setTimeout(() => {
                if (iref.current) {
                    iref.current.focus()
                }
            }, 0)
        }
    }

    useCloseOnClickOutside(open, () => {
        setOpen(false)
        setQuery('')
    }, sref, ref)

    ValueComponent ||= SelectValue

    const {setToAlign} = useAlignOnScroll(ref.current, alignOptions)

    return <>
        <ValueComponent
            innerRef={ref}
            onClick={() => {
                setOpen(p => !p)
                focusQuery()
            }}
            onKeyDown={event => {
                if (event.key === 'Enter' || event.key === ' ') {
                    setOpen(true)
                    event.preventDefault()
                }
            }}
            value={value}
            placeholder={placeholder}
            valueOption={valueOption_}
            extraClassName={extraClassName}
            onNull={nullable ? () => onUpdate(null, null) : undefined}
            style={style}
        />
        {open ?
            <Portal parent={ref.current}>
                <div ref={element => {
                    sref.current = element
                    setToAlign(element)
                    align(ref.current, element, alignOptions)}
                } className={className}>
                    {filterable !== false ?
                        <input
                            autoFocus="on"
                            type="text"
                            value={query}
                            ref={iref}
                            onChange={event => setQuery(event.target.value)}
                            className={className.e('query')}
                            placeholder={placeholder}
                        />
                        : null
                    }
                    <ul className={className.e('list').x(listExtraClassName)}>
                        {options.map(option => {
                            const Component = option.OptionComponent || OptionComponent || SelectOption
                            const value = option.value || option
                            const key = option.key || value

                            return <Component
                                key={key}
                                className={className.e('item')}
                                onClick={() => {
                                    setOpen(false)
                                    setQuery('')
                                    onUpdate(value, option)
                                }}
                                onKeyDown={event => {
                                    if (event.key === 'Enter' || event.key === ' ') {
                                        setOpen(false)
                                        setQuery('')
                                        onUpdate(value, option)
                                        event.preventDefault()
                                    }
                                }}
                                option={option}
                            />
                        })}
                        {onCreate && query && !queryOption ?
                            <CreateOpton option={"create: " + query}
                                className={className.e('item')}
                                onClick={() => {
                                    setOpen(false)
                                    setQuery('')
                                    onCreate(query)
                                }}
                                onKeyDown={event => {
                                    if (event.key === 'Enter' || event.key === ' ') {
                                        setOpen(false)
                                        onCreate(query)
                                        event.preventDefault()
                                    }
                                }}
                                query={query}
                            />
                            : null
                        }
                    </ul>
                </div>
            </Portal>
            : null
        }
    </>
}

function SelectValue({valueOption, value, innerRef, onClick, onKeyDown, placeholder, nullable, style}) {
    return <Button onClick={onClick} innerRef={innerRef} onKeyDown={onKeyDown} style={style}>
        {get(valueOption, 'label') || value || placeholder || 'select…'}
        {nullable ?
            <CloseIcon />
            : null
        }
    </Button>
}


function SelectOption({className, onClick, option, onKeyDown}) {
    return <li className={className} onKeyDown={onKeyDown} onClick={onClick} tabIndex={0} role="button">
        {option.optionLabel || option.label || option.value}
    </li>
}

function CreateOpton({query, onClick, onKeyDown, className}) {
    return <li
        className={className} onKeyDown={onKeyDown} onClick={onClick} tabIndex={0} role="button"
    >
        <span className="sub">create:</span> {query}
    </li>
}


export function Options({options, parentRef, sref, setToAlign, alignOptions, className, filterable, query, iref, setQuery, listExtraClassName, OptionComponent, setOpen, onUpdate, onCreate, queryOption}) {
    return <Portal parent={parentRef.current}>
        <div ref={element => {
            sref.current = element
            setToAlign(element)
            align(parentRef.current, element, alignOptions)}
        } className={className}>
            {filterable !== false ?
                <input autoFocus="on" type="text" value={query} ref={iref} onChange={event => setQuery(event.target.value)} className={className.e('query')} />
                : null
            }
            <ul className={className.e('list').x(listExtraClassName)}>
                {options.map(option => {
                    const Component = option.OptionComponent || OptionComponent || SelectOption
                    const value = option.value || option
                    const key = option.key || value

                    return <Component
                        key={key}
                        className={className.e('item')}
                        onClick={() => {
                            setOpen(false)
                            setQuery('')
                            onUpdate(value, option)
                        }}
                        onKeyDown={event => {
                            if (event.key === 'Enter' || event.key === ' ') {
                                setOpen(false)
                                setQuery('')
                                onUpdate(value, option)
                                event.preventDefault()
                            }
                        }}
                        option={option}
                    />
                })}
                {onCreate && query && !queryOption ?
                    <CreateOpton option={"create: " + query}
                        className={className.e('item')}
                        onClick={() => {
                            setOpen(false)
                            setQuery('')
                            onCreate(query)
                        }}
                        onKeyDown={event => {
                            if (event.key === 'Enter' || event.key === ' ') {
                                setOpen(false)
                                onCreate(query)
                                event.preventDefault()
                            }
                        }}
                        query={query}
                    />
                    : null
                }
            </ul>
        </div>
    </Portal>
}