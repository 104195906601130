import React from 'react'

import {useAlignOnScroll} from './popup-menu'
import bem from '../utils/bem'
import Button from './button'
import FilterBuilder from './filter-builder'
import get from './get'
import FilterIcon from './icons/filter-icon'
import {Options} from './popup-select'
import {useOptions} from './producer-select'
import Query from './query'
import RatingQuery from './rating-query'
import SortBuilder from './sort-builder'
import './query-builder.scss'
import useCloseOnClickOutside from '../hooks/use-close-on-click-outside'


export default function QueryBuilder({fields, value, onUpdate, quickFilterFields, separated}) {
    const [open, setOpen] = React.useState(false)

    const filterActive = get(value, 'filter', 'items', 'length') > 0

    return <div className="editor query-builder">
        {separated ?
            <>
                <ProducerSelect
                    value={value}
                    onUpdate={onUpdate}
                />
                <Query
                    name="Products..."
                    value={get(value, 'product.fts') || ''}
                    onChange={value => onUpdate(prev => ({...prev, 'product.fts': value}))}
                />
            </>
            : <Query
                name="Search producers, products..."
                value={get(value, 'fts') || ''}
                onChange={value => onUpdate(prev => ({...prev, 'fts': value}))}
            />
        }
        <div className="spacer">
            <RatingQuery
                value={get(value, 'product_rating.normalized_rating') || '*'}
                onChange={value => onUpdate(prev => ({...prev, 'product_rating.normalized_rating': value}))}
            />
            <Button
                active={filterActive}
                onClick={() => setOpen(prev => !prev)}
            >
                <FilterIcon color="--on-primary"/>
            </Button>
        </div>
        <div className={bem('foldable').m({open}).x('spacer spacer--vertical')}>
            <div className="filter-container">
                <div className="filter-container__label">Filter</div>
                <FilterBuilder
                    fields={fields}
                    value={get(value, 'filter')}
                    quickFilterFields={quickFilterFields}
                    onUpdate={u => onUpdate(prev => ({...prev, filter: u(prev.filter)}))}
                />
            </div>
            <div className="filter-container">
                <div className="filter-container__label">Sort</div>
                <SortBuilder
                    fields={fields}
                    value={get(value, 'sort')}
                    onUpdate={u => onUpdate(prev => ({...prev, sort: u(prev.sort)}))}
                />
            </div>
        </div>
    </div>
}

function ProducerSelect({value, onUpdate}) {
    const [open, setOpen] = React.useState(false)
    const query = get(value, 'producer.fts') || ''
    const ref = React.useRef()
    return <div className="query-builder__producer-select" ref={ref}>
        <Button
            active={open}
            onClick={() => setOpen(p => !p)}
            disabled={!query}
        >
        </Button>
        <Query
            name="Producers..."
            value={get(value, 'producer.fts') || ''}
            onChange={value => onUpdate(prev => ({...prev, 'producer.fts': value, 'producer_id': null}))}
        />
        {open ?
            <ProducerOptions
                query={query}
                parentRef={ref}
                onRequestClose={() => setOpen(false)}
                setOpen={setOpen}
                onUpdate={v => {
                    onUpdate(prev => ({...prev, 'producer_id': v}))
                }}
            />
            : null
        }
    </div>
}

function ProducerOptions({query, parentRef, setOpen, onRequestClose, onUpdate}) {
    const sref = React.useRef()
    const className = bem('popup-select')
    const options = useOptions(query)
    const {setToAlign} = useAlignOnScroll(parentRef.current)

    useCloseOnClickOutside(true, onRequestClose, sref, parentRef)


    return <Options
        filterable={false}
        options={options}
        className={className}
        parentRef={parentRef}
        sref={sref}
        setToAlign={setToAlign}
        setQuery={() => {}}
        setOpen={setOpen}
        onUpdate={onUpdate}
    />
}
