import React from 'react'
import TextareaAutosize from 'react-textarea-autosize'

import AddIcon from './components/icons/add-icon'
import Button from './components/button'
import DeleteIcon from './components/icons/delete-icon'
import {useInit} from './components/init-context'
import {ObjectContext} from './components/object-store'
import {useDelete} from './components/object-store'
import {useField} from './components/object-store'
import {useFocusBlurSaveField} from './components/object-store'
import {useList} from './components/object-store'
import {useSaveListener} from './components/object-store'
import {useSave} from './components/object-store'
import Page from './components/page'
import genId from './utils/gen_id'
import QProduct from './components/q-product'

import './page-templates.scss'


export default function PageProduct() {

    return <Page extraClassName="page-templates" top={<div />}>
        <QProduct />
    </Page>
}
