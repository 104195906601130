import React from 'react'
import TextareaAutosize from 'react-textarea-autosize'

import useDebounce from '../hooks/use-debounce'
import {useDebounceWithForce} from '../hooks/use-debounce'
import useMergeName from '../hooks/use-merge-name'
import useProductDropResource from '../hooks/use-product-drop-resource'
import {makeRequest} from '../http'
import Merger from '../merger'
import {scoreProductByImportance} from '../model/score-product-by-importance'
import bem from '../utils/bem'
import genId from '../utils/gen_id'
import {getScore} from '../utils/match-filter'
import shouldClick from '../utils/should-click'
import useHash from '../utils/use-hash'
import Button from './button'
import CloseIcon from './close-icon'
import DoneIcon from './done-icon'
import DuplicateIcon from './duplicate-icon'
import DuplicateWarning from './duplicate-warning'
import FileInputButton from './file-input-button'
import get from './get'
import AddIcon from './icons/add-icon'
import GmapIcon from './icons/gmaps-icon'
import GroupedIcon from './icons/grouped-icon'
import IndividualIcon from './icons/individual-icon'
import ShelfIcon from './icons/shelf-icon'
import TemplateIcon from './icons/template-icon'
import TrashcanIcon from './icons/trashcan-icon'
import {useInit} from './init-context'
import {MainRatingSelect} from './main-rating'
import Modal from './modal'
import MoreIcon from './more-icon'
import NoteIcon from './note-icon'
import {ObjectContext} from './object-store'
import {useField} from './object-store'
import {useFocusBlurSaveField} from './object-store'
import {useMerge} from './object-store'
import {useObjectStore} from './object-store'
import {useObject} from './object-store'
import {useSaveField} from './object-store'
import {useSave} from './object-store'
import {useTrash} from './object-store'
import {useUpdate} from './object-store'
import PopupMenu from './popup-menu'
import ProducersProductList from './producers-product-list'
import {AddTagButton} from './product-editor'
import {ProductEditorLine} from './product-editor'
import Query from './query'
import {Resources} from './resource'
import {useAddProducer} from './selection-shelf'
import {useAddProduct} from './selection-shelf'
import sorted from './sorted'
import Switcher from './switcher2'
import {ProducerTagSelect} from './tag'
import {TagEditor} from './tag'
import {Tag} from './tag'
import useLocalStorageState from '../utils/use-local-storage-state'
import {getValue} from '../utils/use-local-storage-state'
import './editor.scss'
import './producer-editor.scss'


const duplicateFields = ['producer.slug', 'producer.google_place_id']


export default function ProducerEditor({producer, ...props}) {
    return <ObjectContext model="producer" id={producer.id}>
        <ProducerEditor_ producer={producer} key={get(producer, 'id')} {...props} />
    </ObjectContext>
}

function ProducerEditor_({producer, setHashParam, query, setQuery}) {
    const {state} = useObjectStore()
    const nameProps = useFocusBlurSaveField('name')
    const addressProps = useFocusBlurSaveField('address')
    const noteProps = useFocusBlurSaveField('note')
    const [resources, setResources] = useSaveField('resources')
    const [products, setProducts] = useField('products')
    const className = bem('editor')
    const noteRef = React.useRef()
    const [productsView, setProductsView] = useLocalStorageState('productsView', 'grouped')

    const [hasNote, setHasNote] = React.useState(noteProps.value && noteProps.value.length > 0)
    const save = useSave()
    const update = useUpdate()
    const [debouncedNewProductName, setDebouncedNewProductName] = useDebounceWithForce(query)

    function addProduct(object) {
        object.id ||= genId()
        object.producer_id = producer.id

        object.name ||= getValue('product-preset-name', '')
        object.tags ||= getValue('product-preset-tags', [])
        object.ratings ||= []

        const product_id = object.id
        if (object.tags) {
            const tags = []
            object.tags.forEach(tag => {
                const id = genId()
                tags.push(id)
                save('product_tag', {tag, product_id, value: null, id})
            })
            object.tags = tags
        }


        save('product', object)
            .then(() => {
                setHashParam('edit', `producer/${producer.id}/product/${object.id}`)
            })
        update([{id: producer.id, products: prev => ([object.id, ...prev,])}])
        setAutofocus(0)
        setQuery('')
        setDebouncedNewProductName('')

    }

    function handleShowProduct(event, product) {
        setHashParam('edit', `producer/${producer.id}/product/${product.id}`)
    }

    function handleShowProducts(event, products) {
        setHashParam('edit', `producer/${producer.id}/products/${products.map(p => p.id).join(',')}`)
    }

    const {mergeActive, mainToMergeSelection, addToMerge, addToMergeAlways, selection, cancelMerge} = useMerge('product', otherId => setProducts(prev => prev.filter(id => id !== otherId)))

    const matching = React.useMemo(() => {
        if (!products) {return []}
        if (!debouncedNewProductName) {
            return sorted(
                products.map(id => ({id, score: scoreProductByImportance(state[id], state)})),
                p => p.score, -1
            )
                .map(p => p.id)
        }
        return filterSort(products, state, debouncedNewProductName)
    }, [products, debouncedNewProductName, state])

    const [autoFocus, setAutofocus] = React.useState(nameProps.value && nameProps.value.length > 0 ? 'query' : 'name')

    const className2 = bem('producer-editor')
    const {mergeNameSelected, addToMergeName, addToMergeNameAlways} = useMergeName('product')
    const {setProductsIds} = useAddProduct()


    return <div className={className}>
        <Merger selection={selection} cancelMerge={cancelMerge} />
        <div className={className2}>
            <div className={className.e('slug')}>
                {producer.slug} {producer.coordinate_latitudine || '-'} {producer.coordinate_longitudine || '-'}
            </div>
            <div className={className.e('i')}>
                <input type="text" {...nameProps} placeholder="Name" autoFocus={autoFocus === 'name'} className={className.e('i').e('m')}/>
                <DuplicateWarning model="producer" id={producer.id} operator="OR" fields={duplicateFields} />
            </div>
            {producer.address ?
                <input type="text" {...addressProps} placeholder="Address" autoFocus={autoFocus === 'address'} className={className.e('i').e('m')}/>
                : null
            }
            <div  className={className2.e('x')}>
                <div className={className.e('s')}>
                    <ProducerEditorLine
                        noteRef={noteRef}
                        hasNote={hasNote}
                        setHasNote={setHasNote}
                    />
                </div>
                {resources && resources.length > 0 ?
                    <Resources ids={resources} model="producer" />
                    : null
                }
                <TextareaAutosize
                    {...noteProps}
                    placeholder="Note..."
                    ref={noteRef}
                />
            </div>
            <div className={className.e('i')}>
                <input
                    type="text"
                    className={className.e('i').e('m')}
                    autoFocus={autoFocus === 'query'}
                    placeholder="Search..."
                    value={query}
                    onChange={event => setQuery(event.target.value)}
                    onFocus={() => setAutofocus('query')}
                    onKeyDown={event => {
                        if (event.key == 'Enter') {
                            addProduct({name: query})
                        }
                    }}
                />
                <Switcher
                    value={productsView}
                    options={[{value: "grouped", message: <GroupedIcon color="--on-primary"/>}, {value: "edit", message: <IndividualIcon color="--on-primary"/>}]}
                    onSwitch={(_, value) => setProductsView(value)}
                />
                <Button onClick={() => addProduct({})}>
                    <AddIcon color="white"/>
                </Button>
            </div>
        </div>
        {productsView === 'grouped' ?
            <ProducersProductList
                products={matching}
                onShowProduct={handleShowProduct}
                onShowProducts={handleShowProducts}
                onSelectProducts={(event, products) => {
                    event.handled = true
                    setProductsIds(prev => ([...(prev || []), ...products.map(p => p.id)]))
                }}
            />
            : matching && matching.map((id, index) => {
                return <ProductEditor
                    key={id}
                    id={id}
                    producer={producer}
                    className={className}
                    addProduct={addProduct}
                    autoFocus={index === 0 && autoFocus === 0}
                    setHashParam={setHashParam}
                    onSelect={addToMerge || addToMergeName}
                    addToMergeName={addToMergeNameAlways}
                    selected={mainToMergeSelection === id || mergeNameSelected === id}
                    addToMerge={addToMergeAlways}
                />
            })
        }
        <NewProductFromTemplate query={query} onClick={addProduct} />
        {query ?
            <NewProduct query={query} onClick={name => addProduct({name})}/>
            : null
        }
    </div>
}



export function ProducerEditorLine({noteRef, hasNote, setHasNote, panTo, done, prefix}) {
    const {addProducer} = useAddProducer()
    const [id] = useField('id')
    const [locating, setLocating] = React.useState(false)
    const [resources, setResources] = useField('resources')
    const [tags, setTags] = useField('tags')
    const [rating, setRating] = useSaveField('rating')
    const [status, setStatus] = useSaveField('_status')
    const [producer_id] = useSaveField('id')
    const [address] = useField('address')
    const [google_place_id] = useField('google_place_id')
    const trash = useTrash()

    const save = useSave()

    function removeTag(tag) {
        setTags(prev => {
            return prev.filter(t => t !== tag)
        })
    }

    function addTag(tag) {
        const id = genId()
        save({id, producer_id, tag})

        setTags(prev => {
            return [id, ...prev]
        })
    }

    function onSelectFromMore(update) {
        if (update === 'shelve') {
            addProducer(id)
        }
        if (update === 'trash') {
            trash('producer', id)
        }
        if (update === 'no-g') {
            setStatus(prev => {
                if (prev === 'NO-G') {
                    return null
                }
                return 'NO-G'
            })
        }
        if (update === 'locate') {
            setLocating(true)
        }
        if (update === 'products-notes') {
            window.open('#products-notes&producer=' + id)
        }
    }

    return <>
        <div className="spacer spacer--wrap">
            <PopupMenu
                values={[
                    {value: 'locate', message: 'locate', icon: <GmapIcon color="--on-primary" />},
                    {value: 'shelve', message: 'shelve', icon: <ShelfIcon color="--on-primary" />},
                    {value: 'products-notes', message: 'products notes', icon: <NoteIcon color="white" />},
                    {value: 'trash', message: 'trash', danger: true, icon: <TrashcanIcon color="--on-primary" />},
                ].filter(v => v)}
                value={null}
                closeOnSelect
                onSelect={onSelectFromMore}
            >
                <MoreIcon />
            </PopupMenu>
            {prefix || null}
            <MainRatingSelect
                value={rating}
                onUpdate={setRating}
            />
            <GoogleMapsLink producer={{google_place_id, address}} />
            <FileInputButton
                model="producer"
                modelId={producer_id}
            />
        </div>
        <div className="spacer spacer--wrap">
            <ProducerTagSelect
                ValueComponent={AddTagButton}
                producerId={id}
            />
            {tags && tags.map(id => {
                return <TagEditor key={id} id={id} model="producer_tag" />
            })}
        </div>
        {done ?
            <Button onClick={done}>
                <DoneIcon color="white" />
                Done
            </Button>
            : null
        }
        {status || null}
        {locating ?
            <LocateModal
                position={locating ? 'open' : 'closed'}
                onRequestClose={() => setLocating(false)}
                panTo={panTo}
            />
            : null
        }
    </>
}

export function NewProduct({query, onClick}) {
    return <div className="new-item-card" role="button" onClick={() => onClick(query)} tabIndex={0}>
        <span className="new-item-card__label">create</span>
        <span className="flex1">
            {query}
        </span>
        <AddIcon
            width="32px"
            height="32px"
            color="#663299"
        />
    </div>
}

function ProductEditor({id, ...props}) {
    return <ObjectContext model="product" id={id}>
        <ProductEditor_ id={id} {...props} />
    </ObjectContext>
}

function ProductEditor_({
    autoFocus, id, producer, className, addProduct, setHashParam, selected, onSelect,
    addToMergeName, addToMerge,
}) {
    const {state} = useObjectStore()
    const [name, setName] = useSaveField('name')
    const [slug] = useSaveField('slug')
    const noteProps = useFocusBlurSaveField('note')
    const [resources, setResources] = useSaveField('resources')
    const product = useObject(id)

    const noteRef = React.useRef()

    const [hasNote, setHasNote] = React.useState(noteProps.value && noteProps.value.length > 0)

    function handleClick(event) {
        if (!shouldClick(event)) {return}
        if (onSelect) {
            onSelect(id)
            return
        }
        if (producer) {
            setHashParam('edit', `producer/${producer.id}/product/${id}`)
        }
        else {
            setHashParam('edit', `product/${id}`)
        }
    }

    const save = useSave()

    const {dropProps} = useProductDropResource({id, parentClassName: 'product-editor'})

    return <div className={className.e('e').m({selected, selectable: !!onSelect}).x('product-editor')} {...dropProps} onClick={handleClick}>
        <div className={className.e('slug')}>
            {slug}
        </div>
        <div className={className.e('i')}>
            <span
                className={className.e('i').e('m')}
            >
                {name}
            </span>
            <Button
                onClick={() => {
                    duplicateProduct(product, save, state, addProduct)
                }}
            >
                <DuplicateIcon color="white" />
            </Button>
        </div>
        <div className={className.e('s')}>
            <ProductEditorLine
                product={product}
                addToMergeName={addToMergeName}
                toggleMergeActive={addToMerge}
            />
        </div>
        {resources && resources.length > 0 ?
            <Resources ids={resources} model="producer" />
            : null
        }
        <TextareaAutosize
            {...noteProps}
            ref={noteRef}
            placeholder="Note"
        />
    </div>
}

function duplicateProduct(product, save, state, addProduct) {
    const id = genId()
    const copy = {name: product.name + ' (copy)', id, producer_id: product.producer_id, tags: []}
    if (!product.tags) {
        return
    }
    product.tags.forEach(tagId => {
        const tag = state[tagId]
        if (tag) {
            const tagId = genId()
            save('product_tag', {product_id: id, tag: tag.tag, id: tagId})
            copy.tags.push(tagId)
        }
    })
    addProduct(copy)
}


function filterSort(ids, state, query) {
    if (!query) {return ids}
    const words = query.split(' ')
    const lowerQuery = query.toLowerCase()
    const lowerWords = lowerQuery.split(' ')

    const prepared = ids
        .map(id => {
            const object = state[id]
            const score = getScore(object.name, query, lowerQuery, words, lowerWords)
            return {object, score}
        })

    return prepared
        .filter(p => p.score !== 0)
        .map(p => p.object.id)


}

function LocateModal({position, onRequestClose, panTo}) {
    const [spinning, setSpinning] = React.useState(false)
    const [name] = useSaveField('name')
    const [address] = useSaveField('address')
    const [id] = useSaveField('id')
    const [query1, setQuery1] = React.useState(name)
    const [query2, setQuery2] = React.useState(address)
    const [results, setResults] = React.useState([])
    const save = useSave()
    const {merge} = useMerge('producer')
    const {setHashParam} = useHash()

    React.useEffect(() => {
        if (position === 'open' && query1) {
            search()
        }
    }, [position])

    function search() {
        setSpinning(true)
        makeRequest('g-get-results-from-query', {
            body: JSON.stringify(query1 + ' ' + query2)
        })
            .then(response => {
                setResults(response)
                setSpinning(false)
            })
            .catch(error => {
                console.error(error)
                window.alert('ERROR', error)
                setSpinning(false)
            })
    }

    function locate(googlePlace) {
        const [coordinate_latitudine, coordinate_longitudine] = googlePlace.coordinate.split(', ')
        if (googlePlace.id) {
            setSpinning(true)
            merge(googlePlace.id, [id])
                .then(() => {
                    onRequestClose()
                    setSpinning(false)
                    setHashParam('edit', `producer/${googlePlace.id}`)
                })
                .catch(error => {
                    console.error(error)
                    setSpinning(false)
                })
        }
        const update = {
            id,
            google_place_id: googlePlace.google_place_id,
            address: googlePlace.address,
            coordinate_latitudine: parseFloat(coordinate_latitudine),
            coordinate_longitudine: parseFloat(coordinate_longitudine),
        }
        setSpinning(true)
        save('producer', update)
            .then(producer => {
                if (panTo) {
                    panTo(update)
                }
                onRequestClose()
                setSpinning(false)
            })
            .catch(error => {
                console.error(error)
                setSpinning(false)
            })
    }

    return <Modal onRequestClose={onRequestClose} open={position === 'open'} slot={1}>
        <div className="modal__padding">
            <div className="producer-view__locating-sheet__top-bar">
                <Button onClick={onRequestClose} className="icon-button">
                    <CloseIcon color="--primary" />
                </Button>
            </div>
            <Query name="Name..." value={query1} onChange={setQuery1} />
            <Query name="Address..." value={query2} onChange={setQuery2} />
            <button className="button" onClick={search} disabled={spinning}>Search</button>
        </div>
        <ul className="locate-modal__results">
            {results.map(r => {
                return <li key={r.google_place_id} className="locate-modal__results__item"
                    onClick={(event) => {
                        if (spinning) {return}
                        if (event.target.tagName === 'A') {return}
                        locate(r)
                    }}
                >
                    <div className="locate-modal__results__item__name">{r.name}</div>
                    {r.id ?
                        <div className="locate-modal__results__item__merge">MERGE {r.id}</div>
                        : null
                    }
                    <div className="locate-modal__results__item__address">{r.address}</div>
                    <div className="locate-modal__results__item__sub">{r.types.join(', ')}</div>
                    <div>
                        <a href={r.google_maps} target="_blank">Google Maps</a>
                    </div>
                </li>
            })}
        </ul>
    </Modal>
}



function GoogleMapsLink({producer}) {
    if (!producer.google_place_id) {return null}
    return <span><a
        href={`https://www.google.com/maps/search/?api=1&query=${producer.address}&query_place_id=${producer.google_place_id}`}
        target="_blank"
        rel="noopener noreferrer"
        className="button button--google-maps-link"
    >
        <GmapIcon color="white" />
    </a></span>
}


function NewProductFromTemplate({query, onClick}) {
    const init = useInit()
    const save = useSave()

    const templates = React.useMemo(() => {
        const words = query.split(' ')
        const lowerQuery = query.toLowerCase()
        const lowerWords = lowerQuery.split(' ')

        return init.template.filter(template => {
            return getScore(template.name, query, lowerQuery, words, lowerWords) > 0
        })
    }, [query])

    function handleCreate(template) {
        const data = JSON.parse(template.data)
        onClick({name: template.name, tags: data.tags})
    }

    if (!query) {return null}

    return templates.map(template => {
        const data = JSON.parse(template.data)
        const tags = data.tags
        return <div
            key={template.id}
            role="button"
            onClick={() => handleCreate(template)}
            className="new-product-from-template"
            tabIndex={0}
            onKeyDown={event => {
                if (event.key == 'Enter' || event.key == ' ') {
                    handleCreate(template)
                    event.preventDefault()
                }
            }}
        >
            <span className="new-item-card__label">create</span>
            <div className="new-product-from-template__main">
                {template.name}
                <div className="new-product-from-template__tags">
                    {tags ? tags.map(t => <span key={t} className="new-product-from-template__tag">{t}</span>) : null}
                </div>
            </div>
            <TemplateIcon width="32px" height="32px" color="#663299"/>
        </div>
    })
}
