import React from 'react'

import useCloseOnClickOutside from '../hooks/use-close-on-click-outside'
import bem from '../utils/bem'
import genId from '../utils/gen_id'
import Button from './button'
import AddIcon from './icons/add-icon'
import Close2Icon from './icons/close2-icon'
import DeleteIcon from './icons/delete-icon'
import {useInit} from './init-context'
import {useSetInit} from './init-context'
import {ObjectContext} from './object-store'
import {updateStateObjects} from './object-store'
import {useDeletes} from './object-store'
import {useDelete} from './object-store'
import {useField} from './object-store'
import {useFocusBlurSaveField} from './object-store'
import {useObjectStore} from './object-store'
import {useSaveField} from './object-store'
import {useSaves} from './object-store'
import {useSave} from './object-store'
import {useUpdate} from './object-store'
import {align} from './popup-menu'
import {useAlignOnScroll} from './popup-menu'
import {QuickSelect} from './popup-select'
import Portal from './portal'
import './popup-select.scss'
import './tag.scss'
import GotoIcon from './icons/goto-icon'
import DownIcon from './icons/down-icon'
import UpIcon from './icons/up-icon'
import tagFollowups from '../tag_followups.json'



export function Tag({tag, onClick, innerRef, value, extraClassName, count, icon, ...props}) {
    const color = getColorForTag(tag)
    const style = {backgroundColor: color, borderColor: color, color: 'white'}
    return <span
        {...props}
        ref={innerRef}
        className={extraClassName + ' ' + bem("tag").m({clickable: !!onClick})}
        style={style}
        onClick={onClick}
        role={onClick ? 'button' : undefined}
        tabIndex={onClick ? 0 : undefined}
    >
        {tag}{value ? '(' + value + ')' : null}
        {count ?
            <span className="sub">({count})</span>
            : null
        }
        {icon || null}
    </span>
}


export function TagEditor({model, id}) {
    return <ObjectContext
        model={model} id={id}
    >
        <TagEditor_ model={model} id={id} />
    </ObjectContext>
}

function TagEditor_({model, id}) {
    const {setState} = useObjectStore()
    const [open, setOpen] = React.useState(false)
    const [tag, setTag] = useSaveField('tag')
    const [ownerId] = useField(model.replace('_tag', '_id'))

    const [value, setValue] = useField('value')
    const valueProps = useFocusBlurSaveField('value')
    const sref = React.useRef()
    const ref = React.useRef()
    useCloseOnClickOutside(open, () => setOpen(false), sref, ref)
    const {setToAlign} = useAlignOnScroll(ref.current)
    const hasNumberValue = parseInt(value, 10) == value

    const del = useDelete()
    const init = useInit()

    function handleDel() {
        updateStateObjects(setState, [{id: ownerId, tags: prev => prev.filter(p => p !== id)}])
        del(model, id)
    }

    React.useEffect(() => {
        if (!open) {
            if (value != valueProps.value) {
                if (!value && !valueProps.value) {return}
                valueProps.onBlur({target: {value: valueProps.value}})
            }
        }
    }, [open])

    if (tag === undefined) {return null}

    function increaseValue() {
        addToValue(1)
    }

    function decreaseValue() {
        addToValue(-1)

    }

    function addToValue(diff) {
        const next = parseInt(value, 10) + diff
        valueProps.onBlur({target: {value: next}})
    }

    function followTag(ftag) {
        setTag(ftag)
    }

    const filter = encodeURI(JSON.stringify({"operator": "AND", "items": [{"field": model + ".tag", "operand": tag, "operator": "="}]}))
        .replace(/=/g, '%3D')


    return <>
        <Tag tag={tag} value={value} innerRef={ref} onClick={() => setOpen(p => !p)}/>
        {open ?
            <Portal parent={ref.current}>
                <div
                    ref={element => {
                        sref.current = element
                        align(ref.current, element)
                        setToAlign(element)
                    }}
                    className="popup-select tag-editor"
                >
                    <div className="tag-editor__line">
                        <input
                            type="text"
                            ref={element => {
                                if (element) {
                                    setTimeout(() => element.focus(), 0)
                                }
                            }}
                            autoFocus
                            placeholder="value"
                            className="popup-editor-input"
                            {...valueProps}
                        />
                        {hasNumberValue ?
                            <div className="stack-buttons">
                                <Button onClick={increaseValue}>
                                    <UpIcon color="--on-primary" />
                                </Button>
                                <Button onClick={decreaseValue}>
                                    <DownIcon color="--on-primary" />
                                </Button>

                            </div>
                            : null
                        }
                        <a className="button" href={`#${model.split('_')[0] + 's'}&filter=${filter}`} target="_blank">
                            <GotoIcon color="--on-primary" />
                        </a>
                        <Button onClick={handleDel}>
                            <DeleteIcon color="--on-primary" />
                        </Button>
                        <TagFollowups
                            tagFollowups={tagFollowups[tag]}
                            onClick={followTag}
                        />
                    </div>
                    <TagValues
                        values={init.producer_tag_values[tag]}
                        onClick={value => valueProps.onBlur({target: {value}})}
                    />
                </div>

            </Portal>
            : null
        }
    </>
}


function TagFollowups({tagFollowups, onClick}) {
    if (!tagFollowups || tagFollowups.length === 0) {return null}
    return <>
        {tagFollowups.map(ftag => {
            return <Button onClick={() => onClick(ftag)} key={ftag}>{ftag}</Button>
        })}
    </>
}

function TagValues({values, onClick}) {
    if (!values || values.length === 0) {return null}
    return <>
        {values.map(value => {
            return <Button onClick={() => onClick(value)} key={value}>{value}</Button>
        })}
    </>
}


export function TagSelect({value, onUpdate, extraClassName, onCreate, ValueComponent, model, nullable}) {
    const init = useInit()
    const setInit = useSetInit()
    const allOptions = init[model + '_options']
    const lastUsed = getFromLocalStorage('lastUsed', {[model]: []})[model]
    const options = React.useMemo(() => {
        const values = lastUsed || []
        return [
            ...values.map(value => {
                return {
                    value,
                    key: 'lastUsed--' + value,
                    message: value,
                }
            }),
            {
                key: 'separator',
                OptionComponent: () => <li><hr/></li>,
            },
            ...allOptions,
        ]

    }, [allOptions, lastUsed])

    const handleUpdate = (...args) => {
        const [tag] = args
        updateLastUsed(tag)
        onUpdate(...args)
    }
    const handleCreate = (...args) => {
        const [tag] = args
        updateLastUsed(tag)
        onCreate(...args)
    }

    function updateLastUsed(tag) {
        setInit(prev => {
            const next = {...prev}
            next.lastUsed ||= getFromLocalStorage('lastUsed', {})
            next.lastUsed[model] ||= []
            next.lastUsed[model] = [
                tag,
                ...next.lastUsed[model].filter(t => t !== tag)
            ].slice(0, 3)
            setInLocalStorage('lastUsed', next.lastUsed)
            return next
        })
    }

    return <QuickSelect
        value={value}
        onUpdate={handleUpdate}
        options={options}
        ValueComponent={ValueComponent || TagButton}
        OptionComponent={OptionComponent}
        alignOptions={{width: true}}
        extraClassName={extraClassName}
        onCreate={handleCreate}
        listExtraClassName="tag-select-list"
        nullable={nullable}
        placeholder="Search..."
    />
}

function getFromLocalStorage(key, initialValue) {
    const value = localStorage.getItem(key)
    if (value) {
        return JSON.parse(value)
    }
    return initialValue
}

function setInLocalStorage(key, value) {
    localStorage.setItem(key, JSON.stringify(value))
}


export function ProducerTagSelect({producerId, ...props}) {
    const save = useSave()
    const update = useUpdate()

    function handleSelect(tag) {
        if (props.onUpdate) {
            props.onUpdate(tag)
            return
        }
        const toAdd = [tag]
        if (tag.indexOf('--') !== -1) {
            toAdd.push(tag.split('--')[0])
        }
        toAdd.forEach(tag => {
            const object = {tag, producer_id: producerId, value: '', id: genId()}
            save('producer_tag', object)
            update([{id: producerId, tags: prev => ([object.id, ...(prev || [])])}])
        })
    }

    return <TagSelect {...props} onUpdate={handleSelect} onCreate={handleSelect} model="producer_tag" />
}


export function ProductTagSelect({productId, ...props}) {
    const save = useSave()
    const update = useUpdate()

    function handleSelect(tag) {
        if (props.onUpdate) {
            props.onUpdate(tag)
            return
        }
        const toAdd = [tag]
        toAdd.forEach(tag => {
            const object = {tag, product_id: productId, value: '', id: genId()}
            save('product_tag', object)
            update([{id: productId, tags: prev => ([object.id, ...(prev || [])])}])
        })
    }

    return <TagSelect {...props} onUpdate={handleSelect} onCreate={handleSelect} model="product_tag" />
}



export function ProductsTagSelect({productsIds, ...props}) {
    const saves = useSaves()
    const update = useUpdate()
    const {state} = useObjectStore()

    function handleSelect(tag) {
        addTagToAll(productsIds, tag, saves, update, state)
    }

    return <TagSelect {...props} onUpdate={handleSelect} onCreate={handleSelect} model="product_tag" />
}

function addTagToAll(productsIds, tag, saves, update, state) {
    const toAdd = [tag]
    if (tag.indexOf('--') !== -1) {
        toAdd.push(tag.split('--')[0])
    }
    const toSaves = []
    const toUpdates = []
    productsIds.forEach(productId => {
        const product = state[productId]
        if (!product) {return}
        const tags = (product.tags || []).map(tagId => state[tagId].tag)
        const tagsToAddToProduct = toAdd.filter(t => tags.indexOf(t) === -1)
        const toAddToProduct = []
        tagsToAddToProduct.forEach(tag => {
            const object = {tag, product_id: productId, value: '', id: genId()}
            toSaves.push(object)
            toAddToProduct.push(object.id)
        })
        if (toAddToProduct.length > 0) {
            toUpdates.push({id: productId, tags: [...product.tags, ...toAddToProduct]})
        }
    })
    update(toUpdates)
    saves('product_tag', toSaves)
}

function deleteTagFromAll(productsIds, tag, dels, update, state) {
    const toDelete = []
    productsIds.forEach(productId => {
        const product = state[productId]
        if (!product) {return}
        if (!product.tags) {return}
        product.tags.forEach(tagId => {
            if (state[tagId].tag === tag) {
                toDelete.push(tagId)
            }
        })
    })
    update(productsIds.map(productId => {
        if (!state[productId]) {return state[productId].tags}
        return {
            id: productId,
            tags: state[productId].tags.filter(tagId => toDelete.indexOf(tagId) === -1)
        }
    }))
    dels('product_tag', toDelete)
}

export function ProductsTagEditor({productsIds, tag, ...props}) {
    const saves = useSaves()
    const dels = useDeletes()
    const {state} = useObjectStore()

    const update = useUpdate()
    const [open, setOpen] = React.useState(false)
    const sref = React.useRef()
    const ref = React.useRef()
    useCloseOnClickOutside(open, () => setOpen(false), sref, ref)
    const {setToAlign} = useAlignOnScroll(ref.current)

    function handleAdd() {
        addTagToAll(productsIds, tag, saves, update, state)
        setOpen(false)
    }

    function handleDel() {
        deleteTagFromAll(productsIds, tag, dels, update, state)
        setOpen(false)
    }

    if (tag === undefined) {return null}

    return <>
        <Tag tag={tag} innerRef={ref} onClick={() => setOpen(p => !p)} {...props} />
        {open ?
            <Portal parent={ref.current}>
                <div
                    ref={element => {
                        sref.current = element
                        align(ref.current, element)
                        setToAlign(element)
                    }}
                    className="popup-select tag-editor"
                >
                    <Button onClick={handleAdd}>
                        <AddIcon color="--on-primary" />
                    </Button>
                    <Button onClick={handleDel}>
                        <DeleteIcon color="--on-primary" />
                    </Button>
                </div>
            </Portal>
            : null
        }
    </>
}


export function TagButton({value, onNull, ...props}) {
    if (onNull) {
        const color = getColorForTag(value)
        const style = {color: color, borderColor: color}

        return <span className={bem("tag-button").m({value: !!value})}>
            <Tag tag={value || 'select…'} {...props} />
            {value ?
                <button className="tag tag--clickable" style={style} onClick={onNull}>
                    <Close2Icon color={color}/>
                </button>
                : null
            }
        </span>
    }
    return <Tag tag={value || 'select…'} {...props} />
}

function OptionComponent({option, ...props}) {
    return <li {...props} tabIndex={0} role="button">
        <Tag tag={option.value} count={option.count} onClick={() => {}}/>
    </li>
}


export function getColorForTag(tag) {
    if (tag == '#') {
        return 'deeppink'
    }
    if (tag == 'TODO') {
        return 'fuchsia'
    }
    if (tag == 'DONE') {
        return 'MediumSeaGreen'
    }
    if (tag === 'Red wine') {
        return 'crimson'
    }
    if (tag === 'Orange wine') {
        return 'orange'
    }
    if (tag === 'White wine') {
        return 'goldenrod'
    }
    if (tag === 'Pink wine') {
        return 'indianred'
    }
    if (tag === 'Spumante wine') {
        return 'midnightblue'
    }
    if (tag === 'Sweet wine') {
        return 'LightSeaGreen'
    }
    if (tag === 'winery') {
        return 'crimson'
    }
    if (tag === 'eat') {
        return 'ForestGreen'
    }
    if (tag === 'see') {
        return 'Orange'
    }
    if (tag === 'sweet') {
        return 'LightSeaGreen'
    }
    if (tag === 'shop') {
        return 'Sienna'
    }
    if (tag === 'drink') {
        return 'DodgerBlue'
    }
    if (tag === 'icecream') {
        return 'Lime'
    }
    if (tag === 'have') {
        return 'orange'
    }
    if (tag === 'specialty_coffe') {
        return 'Brown'
    }
    if (tag === 'coffe') {
        return 'Brown'
    }
    if (tag == 'coctalis') {
        return 'CornflowerBlue'
    }
    if (tag == 'jagodzianka') {
        return 'Navy'
    }
    return getComputedStyle(document.documentElement)
        .getPropertyValue('--primary')
}
