import React from 'react'

import Svg from '../svg'


export default function UpIcon({width, height, color, ...props}) {
    color = getComputedStyle(document.documentElement)
        .getPropertyValue(color || '--content-color') || color

        React.useEffect(() => console.log('color', color), [color])

    return <Svg width={width || '16px'} height={height || '16px'} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="sort" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <polygon id="Path" fill={color || "white"} points="87.8155195 69 12.1844805 69 5 62.9423151 50 25 95 62.9423151 87.8155195 69"></polygon>
        </g>
    </Svg>
}
