import React from 'react'

import bem from '../utils/bem'
import AddButton from './add-button'
import Button from './button'
import {OptionComponent} from './field-select'
import {ValueComponent} from './field-select'
import {Value} from './field-select'
import get from './get'
import AddIcon from './icons/add-icon'
import OnBlurInput from './on-blur-input'
import CloseIcon from './pclose-icon'
import {QuickSelect} from './popup-select'
import Switcher from './switcher2'
import './filter-builder.scss'


export default function FilterBuilder({value, fields, onUpdate, quickFilterFields}) {
    const className = bem("filter-builder")

    function addField(field) {
        const operator = get(fields.filter(f => f.value === field), 0, 'operator') || 'LIKE'
        onUpdate(prev => {
            prev ||= {}
            return {
                ...prev,
                operator: get(prev, 'operator') || 'AND',
                items: [...(prev.items || []), {field: field || null, operand: undefined, operator}]
            }
        })
    }

    function updateFieldItem(index, update) {
        onUpdate(prev => {
            return {
                ...prev,
                items: prev.items.map((i, ix) => {
                    if (index == ix) {
                        const field = update.field || i.field
                        const def = fields.filter(f => f.value === field)[0]
                        return {
                            ...i,
                            operator: i.operator || get(def, 'operator') || 'LIKE',
                            ...update,
                        }
                    }
                    return i
                })
            }
        })
    }

    function removeFilterItem(index) {
        onUpdate(prev => {
            return {
                ...prev,
                items: prev.items.filter((i, ix) => {
                    return ix != index
                })
            }
        })
    }

    return <div className={className}>
        <div className={className.e('top')}>
            <Switcher
                value={get(value, 'operator') || 'AND'}
                options={[{value: 'AND'}, {value: 'OR'}]}
                onSwitch={(_, operator) => {
                    onUpdate(prev => ({...prev, operator}))
                }}
            />
            <div className="spacer spacer--wrap">
                {quickFilterFields ?
                    <QuickFilterFieldsAdder quickFilterFields={quickFilterFields} onAddField={addField} />
                    : null
                }
                <QuickSelect
                    value={null}
                    options={fields}
                    onUpdate={addField}
                    extraClassName={className.e('field')}
                    ValueComponent={AddButton}
                    OptionComponent={OptionComponent}
                />
            </div>
        </div>
        {value && value.items && value.items.map((item, index) => {
            return <FieldItem
                key={index}
                item={item}
                fields={fields}
                onUpdate={update => updateFieldItem(index, update)}
                onRemove={() => removeFilterItem(index)}
            />
        })}
    </div>
}

function FieldItem({item, fields, onUpdate, onRemove}) {
    const className = bem("filter-item")

    const def = fields.filter(f => f.value == item.field)[0]
    const Component = (def && def.FilterComponent) || OperatorOperand

    return <div className={className}>
        <span className={className.e('inner')}>
            <QuickSelect
                value={item.field}
                options={fields}
                onUpdate={update => onUpdate({field: update})}
                ValueComponent={ValueComponent}
                OptionComponent={OptionComponent}
            />
            <Component item={item} onUpdate={onUpdate} className={className.e('operand')} def={def} />
        </span>
        <CloseIcon
            onClick={onRemove}
            role="button"
            className="icon-button icon-button--no-shadow"
            color="--primary"
        />
    </div>
}

const operators = ['LIKE', 'IN', '=', '!=', '>=', '>', '<', '<=', 'IS NULL', 'IS NOT NULL']
    .map(o => ({value: o}))
const noOperandOperators = {'IS NULL': true, 'IS NOT NULL': true}
const emptyFilter = {operator: 'AND', items: []}


function OperatorOperand({item, onUpdate, className, def}) {
    if (def.fields) {
        return <FilterBuilder
            value={get(item, 'query', 'filter') || emptyFilter}
            fields={def.fields}
            onUpdate={update => {
                onUpdate({query: {filter: update(get(item, 'query', 'filter'))}})
            }}
        />
    }

    const noOperand = noOperandOperators[item.operator]
    return <>
        <QuickSelect
            value={item.operator}
            options={operators}
            filterable={false}
            onUpdate={operator => {
                onUpdate({operator})
            }}
        />
        {!noOperand ?
            <OnBlurInput
                type="text"
                value={item.operand || ''}
                onChange={event => onUpdate({operand: event.target.value})}
                className={className}
                autoFocus
            />
            : null
        }
    </>
}


function QuickFilterFieldsAdder({quickFilterFields, onAddField}) {
    return <>
        {quickFilterFields.map((f, i) => {
            return <Button onClick={() => onAddField(f)} key={f + i}>
                <AddIcon color="--on-primary" /> <Value value={f} />
            </Button>
        })}
    </>
}
