import React from 'react'

import get from './components/get'
import DownIcon from './components/icons/down-icon'
import {useInit} from './components/init-context'
import LoadMore from './components/load-more'
import {ObjectContext} from './components/object-store'
import {useField} from './components/object-store'
import {useList} from './components/object-store'
import {useMerge} from './components/object-store'
import {useObject} from './components/object-store'
import {useSaveListener} from './components/object-store'
import Page from './components/page'
import PageSavedQueries from './components/page-saved-queries'
import {ProducerEditorLine} from './components/producer-editor'
import ProducerEditorModal from './components/producer-editor-modal'
import ProducersProductList from './components/producers-product-list'
import QueryBuilder from './components/query-builder'
import {Resources} from './components/resource'
import {producerFields} from './fields'
import useDebounce from './hooks/use-debounce'
import useDropResource from './hooks/use-drop-resource'
import {makeRequest} from './http'
import {NewProducer} from './page-map'
import bem from './utils/bem'
import shouldClick from './utils/should-click'
import translateQuery from './utils/translate-query'
import useHash from './utils/use-hash'
import {buildInitialFilter} from './utils/use-hash'
import './components/products-page.scss'
import './page-products.scss'


const fields = producerFields



const interestedInSaved = {
    models: {producer: true, producer_tag: true},
}


export default function ProducersPage() {
    const {setHashParam, params} = useHash()
    const init = useInit()

    const savedQuery = useObject(get(params, 'query'))

    const [query, setQuery] = React.useState({
        page: {start: 0, end: 50},
        filter: buildInitialFilter(params),
        sort: [{field: 'producer._updated_at', dir: 'DESC'}],
        ...(savedQuery || {}).query,
    })

    React.useEffect(() => {
        function f(e) {
            setHashParam('filter', null)
            const savedQuery = init.saved_query.filter(s => s.id === get(params, 'query'))[0] || {}
            setQuery(prev => {
                return {
                    ...prev,
                    filter: buildInitialFilter(null),
                    'product.fts': '',
                    'producer.fts': '',
                    'fts': '',
                    sort: [{field: 'producer._updated_at', dir: 'DESC'}],
                    ...(savedQuery || {}).query
                }
            })
        }

        window.addEventListener('new_query', f)
        return () => {
            window.removeEventListener('new_query', f)
        }
    }, [get(params, 'query')])

    React.useEffect(() => {
        setQuery(prev => ({...prev, filter: buildInitialFilter(params), sort: [{field: 'producer._updated_at', dir: 'DESC'}], ...(savedQuery || {}).query}))
    }, [savedQuery])

    const tQuery = React.useMemo(() => {
        return {...translateQuery(query)}
    }, [JSON.stringify(query)])
    const dtQuery = useDebounce(tQuery, 100)


    const {ids, refresh, total, spinning} = useList('producer', dtQuery)
    useSaveListener(interestedInSaved, refresh)

    const {mergeActive,  mainToMergeSelection, addToMerge, addToMergeAlways, selection, cancelMerge} = useMerge('producer')


    function handleShowProducts(event, products) {
        setHashParam('edit', `producer/${products[0].producer_id}/products/${products.map(p => p.id).join(',')}`)
    }

    const [productsSelection, setProductsSelection] = React.useState(null)
    function handleSelectProducts(event, products) {
        setProductsSelection(prev => {
            if (prev) {return [...prev, ...products]}
            return products
        })
    }

    function handleExport() {
        return makeRequest(`2/producer_to_text`, {body: JSON.stringify(tQuery)})
            .then(response => {
                return response
            })
    }

    const top = <PageSavedQueries
        model="producer"
        setQuery={setQuery}
        params={params}
        setHashParam={setHashParam}
        query={query}
        total={total}
        onExport={handleExport}
    />

    return <Page top={top}>
        <QueryBuilder
            onUpdate={setQuery}
            value={query}
            fields={fields}
            quickFilterFields={['producer_tag.tag', 'producer.rating']}
        />
        <div className="page-products__content">
            {ids.map(id => {
                return <ProducerEditor
                    key={id}
                    id={id}
                    onSelect={addToMerge}
                    toggleMergeActive={addToMergeAlways}
                    selected={mainToMergeSelection === id}
                    onShowProducts={handleShowProducts}
                    query={dtQuery}
                />
            })}
            <LoadMore
                page={query.page}
                total={total}
                spinning={spinning}
                update={setQuery}
            />
            {query['fts'] ?
                <NewProducer
                    name={query['fts']}
                    onCreate={() => setQuery(prev => ({...prev, 'fts': ''}))}
                />
                : null
            }
        </div>
        <ProducerEditorModal />
    </Page>
}

function ProducerEditor({id, ...props}) {
    return <ObjectContext model="product" id={id}>
        <ProducerEditor_ id={id} {...props} />
    </ObjectContext>
}

function ProducerEditor_({id, selected, onSelect, toggleMergeActive, onShowProduct, onSelectProducts, onShowProducts, query}) {
    const [collapsed, setCollapsed] = React.useState(false)
    const {setHashParam} = useHash()
    const [name] = useField('name')
    const [resources] = useField('resources')
    const [note] = useField('note')
    const producer = useObject(id)
    const ref = React.useRef()

    function handleClick(event) {
        if (!shouldClick(event)) {return}
        if (onSelect) {
            onSelect(id)
        }
        else {
            window.mapkiModalTarget = ref.current
            setHashParam('edit', `producer/${id}`)
        }
    }

    function handleShowProduct(event, product) {
        setHashParam('edit', `producer/${product.producer_id}/product/${product.id}`)
    }

    const {dropProps} = useDropResource({
        model: 'producer',
        modelId: id,
        parentClassName: 'editor__e',
    })

    return <div className={bem("editor__e").m({selected, selectable: !!onSelect})} onClick={handleClick} {...dropProps} ref={ref}>
        <h2 className={bem("editor__e").e('title')}>
            {name} <button className={bem("button").m({rotateable: true, rotated180: collapsed, noBackground: true})} onClick={() => setCollapsed(prev => !prev)}>
                <DownIcon color="--on-content"/>
            </button>
        </h2>
        {!collapsed ?
            <>
                <div className="editor__s">
                    <ProducerEditorLine
                        toggleMergeActive={toggleMergeActive}
                    />
                </div>
                {resources && resources.length > 0 ?
                    <Resources ids={resources} model="product" />
                    : null
                }
                {note ?
                    <pre>{note}</pre>
                    : null
                }
                <ProducersProductList
                    producer={producer}
                    onShowProduct={handleShowProduct}
                    onSelectProducts={onSelectProducts}
                    onShowProducts={onShowProducts}
                    filter={get(query, 'filter')}
                />
            </>
            : null
        }
    </div>
}
