import React from 'react'
import TextareaAutosize from 'react-textarea-autosize'

import useProductDropResource from '../hooks/use-product-drop-resource'
import bem from '../utils/bem'
import genId from '../utils/gen_id'
import zfill from '../utils/zfill'
import BestDatesEditor from './best-dates-editor'
import Button from './button'
import DuplicateWarning from './duplicate-warning'
import FileInputButton from './file-input-button'
import get from './get'
import ShelfIcon from './icons/shelf-icon'
import TagIcon from './icons/tag-icon'
import TrashcanIcon from './icons/trashcan-icon'
import {useInit} from './init-context'
import {MainRatingSelect} from './main-rating'
import MoreIcon from './more-icon'
import NoteIcon from './note-icon'
import {ObjectContext} from './object-store'
import {updateStateObjects} from './object-store'
import {useDelete} from './object-store'
import {useField} from './object-store'
import {useFocusBlurSaveField} from './object-store'
import {useObjectStore} from './object-store'
import {useSaveField} from './object-store'
import {useSave} from './object-store'
import {useTrash} from './object-store'
import {useUpdate} from './object-store'
import PopupMenu from './popup-menu'
import {QuickSelect} from './popup-select'
import ProducerSelect from './producer-select'
import {AddRatingButton} from './rating'
import {PopupRatingEditor} from './rating'
import {RatingEditor} from './rating'
import {Resources} from './resource'
import {useAddProduct} from './selection-shelf'
import {ProductTagSelect} from './tag'
import {TagButton} from './tag'
import {TagEditor} from './tag'
import {Tag} from './tag'
import './editor.scss'
import './product-editor.scss'


const duplicateFields = ['product.slug', 'product.producer_id']

export default function ProductEditor({product, ...props}) {
    return <ObjectContext model="product" id={product.id}>
        <ProductEditor_ product={product} key={get(product, 'id')} {...props}/>
    </ObjectContext>
}

function ProductEditor_({product, ...props}) {
    const className = bem('editor')
    const className2 = bem('product-editor')
    const nameProps = useFocusBlurSaveField('name')
    const noteProps = useFocusBlurSaveField('note')
    const [ratings] = useField('ratings')
    const [resources] = useField('resources')
    const [producerId, setProducerId] = useSaveField('producer_id')
    const noteRef = React.useRef()
    const [hasNote, setHasNote] = React.useState(noteProps.value && noteProps.value.length > 0)

    return <div className={className}>
        <div className={className2}>
            <ProducerSelect
                value={producerId}
                onUpdate={setProducerId}
                alignOptions="match-width"
            />
            <div className={className.e('slug')}>
                {product.slug}
            </div>
            <div className={className.e('i')}>
                <span className={className.e('i').e('m')}>
                    <input type="text" {...nameProps} placeholder="name" />
                </span>
                <DuplicateWarning model="product" id={product.id} fields={duplicateFields} />
            </div>
            <div className={className.e('s')}>
                <ProductEditorLine
                    product={product}
                    noteRef={noteRef}
                    hasNote={hasNote}
                    setHasNote={setHasNote}
                />
            </div>
            {resources && resources.length > 0 ?
                <Resources ids={resources} model="product" />
                : null
            }
            <TextareaAutosize
                {...noteProps}
                placeholder="Note..."
                ref={noteRef}
                autoFocus
            />
        </div>
        {ratings && ratings.map(id => {
            return <RatingEditor id={id} key={id}/>
        })}
    </div>
}


export function Mini({product}) {
    const className = bem('editor')
    const className2 = bem('product-editor')

    const [ratings] = useField('ratings')
    const nameProps = useFocusBlurSaveField('name')
    const noteRef = React.useRef()
    const noteProps = useFocusBlurSaveField('note')
    const [hasNote, setHasNote] = React.useState(noteProps.value && noteProps.value.length > 0)
    const [resources] = useField('resources')
    const {dropProps} = useProductDropResource({id: product.id, parentClassName: 'product-editor-mini'})

    return <div className="product-editor-mini" {...dropProps}>
        <div className={className.e('i')}>
            <span className={className.e('i').e('m')}>
                <input type="text" {...nameProps} placeholder="name" />
            </span>
            <DuplicateWarning model="product" id={product.id} fields={duplicateFields} />
        </div>
        <div className={className.e('s')}>
            <ProductEditorLine
                product={product}
                noteRef={noteRef}
                hasNote={hasNote}
                setHasNote={setHasNote}
            />
        </div>
        {resources && resources.length > 0 ?
            <Resources ids={resources} model="product" />
            : null
        }
        <TextareaAutosize
            {...noteProps}
            placeholder="Note..."
            ref={noteRef}
            className={hasNote ? undefined : 'hidden'}
        />
    </div>
}


export function ProductEditorLine({
    noteRef, hasNote, setHasNote, prefix
}) {
    const {addProduct} = useAddProduct()
    const [id] = useField('id')
    const [resources] = useSaveField('resources')
    const [tags, setTags] = useField('tags')
    const [ratings, setRatings] = useField('ratings')
    const [rating, setRating] = useSaveField('rating')
    const [bestAfter, setBestAfter] = useSaveField('best_after')
    const [bestAt, setBestAt] = useSaveField('best_at')
    const [bestBefore, setBestBefore] = useSaveField('best_before')
    const [_status] = useField('_status')
    const noteProps = useFocusBlurSaveField('note')
    const trash = useTrash()

    function onSelectFromMore(update) {
        if (update === 'shelve') {
            addProduct(id)
        }
        if (update === 'trash') {
            trash('product', id)
        }
    }

    return <>
        <div className="spacer spacer--wrap">
            <PopupMenu
                values={[
                    {value: 'shelve', message: 'shelve', icon: <ShelfIcon color="--on-primary" />},
                    {value: 'trash', message: 'trash', danger: true, icon: <TrashcanIcon color="--on-primary" />},
                ].filter(v => v)}
                value={null}
                closeOnSelect
                onSelect={onSelectFromMore}
            >
                <MoreIcon />
            </PopupMenu>
            {prefix || null}
            <MainRatingSelect
                value={rating}
                onUpdate={setRating}
            />
            <FileInputButton
                model="product"
                modelId={id}
            />
            <BestDatesEditor
                valueBestAfter={bestAfter}
                valueBestAt={bestAt}
                valueBestBefore={bestBefore}
                onUpdateBestAfter={setBestAfter}
                onUpdateBestAt={setBestAt}
                onUpdateBestBefore={setBestBefore}
            />
        </div>
        <div className="spacer spacer--wrap">
            <WantHaveDoneWorkflow />
            <ProductTagSelect
                productId={id}
                ValueComponent={AddTagButton}
            />
            {tags && tags.map(id => {
                return <TagEditor
                    key={id}
                    id={id}
                    model="product_tag"
                />
            })}
            <AddRatingButton
                productId={id}
            />
            {ratings && ratings.map(rid => {
                return <PopupRatingEditor
                    key={rid}
                    id={rid}
                    productId={id}
                />
            })}
        </div>
        {_status || null}
    </>
}


export function upload(setResources, event) {
    const resources = []
    if (event.target.files) {
        Array.from(event.target.files).forEach(file => {
            resources.push({id: genId(), file})
        })
    }
    if (event.dataTransfer) {
        Array.from(event.dataTransfer.items).forEach(i => {
            if (i.kind === 'file') {
                const file = i.getAsFile()
                resources.push({id: genId(), file})
            }
        })
    }
    setResources(prev => {
        prev ||= []
        const next = [...resources, ...prev].map((r, i) => ({...r, rank: zfill(i + 1)}))
        return next
    })
}

function WantHaveDoneWorkflow({product}) {
    const [productId] = useField('id')

    const {
        reduceWant,
        reduceHave,
        reduceDone,
        tags,
    } = useWantHaveDoneWorkflow({productId})


    return <>
        {tags.want ? <M times={get(tags, 'want', 'value')} want={true} onClick={reduceWant} /> : null}
        {tags.have ? <M times={get(tags, 'have', 'value')} have={true} onClick={reduceHave} /> : null}
        {tags.done ? <M times={get(tags, 'done', 'value')} done={true} onClick={reduceDone} /> : null}
    </>
}

export function useWantHaveDoneWorkflow({productId}) {
    const {state, setState} = useObjectStore()
    const tags_ = get(state, productId, 'tags')

    function setTags(value) {
        updateStateObjects(setState, [{id: productId, tags: value}])
    }

    const ptags = (tags_ || []).map(id => state[id])
    const tags = {}
    ;(ptags || []).forEach(t => {
        if (t) {
            tags[t.tag] = t
        }
    })
    const save = useSave()
    const del = useDelete()

    function reduceWant() {
        decreaseValue('want')
        increaseValue('have')
    }

    function reduceHave() {
        decreaseValue('have')
        increaseValue('done')
    }

    function reduceDone() {
        decreaseValue('done')
        increaseValue('want')
    }

    function increaseDone() {
        if (tags.have) {
            decreaseValue('have')
        }
        increaseValue('done')
    }

    function decreaseValue(tag) {
        if (!tags[tag]) {return}
        const prev = tags[tag]
        const next = {...prev, value: dec(prev.value)}
        if (next.value === 0) {
            setTags(ts => ts.filter(t => t.id !== prev.id))
            del('product_tag', next.id)
        }
        else {
            save('product_tag', next)
        }
    }

    function increaseValue(tag) {
        const prev = tags[tag]
        const next = prev ? {...prev, value: inc(prev.value)} : {tag, value: 1, product_id: productId, id: genId()}
        if (!prev) {
            setTags(ts => [next.id, ...ts])
        }
        save('product_tag', next)
    }

    return {increaseDone, reduceWant, reduceHave, reduceDone, tags}
}


export function WHD({product}) {
    const {state} = useObjectStore()
    const ptags = (product.tags || []).map(id => state[id])
    const tags = {}
    ;(ptags || []).forEach(t => {
        if (t) {
            tags[t.tag] = t
        }
    })

    return <>
        {tags.want ? <M times={get(tags, 'want', 'value')} want={true} /> : null}
        {tags.have ? <M times={get(tags, 'have', 'value')} have={true} /> : null}
        {tags.done ? <M times={get(tags, 'done', 'value')} done={true} /> : null}
    </>
}


function inc(value) {
    if (!value) {return 1}
    return parseInt(value, 10) + 1
}

function dec(value) {
    if (!value) {return 0}
    return parseInt(value, 10) - 1
}


export function M({want, times, have, done, onClick}) {
    return range(times).map(i => {
        return <div key={i} className={bem("M").m({want, have, done})} onClick={onClick} role="button"/>
    })
}

function range(times) {
    const r = []
    const v = parseInt(times, 10) || 1
    for (let i = 0; i < v; i++) {
        r.push(i)
    }
    return r
}



export function AddTagButton(props) {
    return <Button {...props}>
        <TagIcon color="white" width="16px" height="16px"/>
    </Button>
}


function ProductTagTemplateSelect({extraClassName, ValueComponent, productId}) {
    const save = useSave()
    const update = useUpdate()

    const init = useInit()
    const tagOptions = init['product_tag_options']

    function onUpdate(value, option) {
        const toAdd = []
        if (option && option.template) {
            JSON.parse(option.template.data).tags.forEach(tag => toAdd.push(tag))
        }
        else if (option) {
            toAdd.push(option.value)
        }
        else if (value) {
            toAdd.push(value)
        }
        toAdd.forEach(tag => {
            const object = {tag, product_id: productId, value: '', id: genId()}
            save('product_tag', object)
            update([{id: productId, tags: prev => ([object.id, ...(prev || [])])}])
        })
    }

    const options = React.useMemo(() => {
        const templates = init['template']
        return [
            ...templates.map(template => {
                return {
                    value: template.name,
                    template,
                }
            }),
            ...tagOptions,
        ]
    }, [tagOptions, init['template']])


    return <QuickSelect
        onUpdate={onUpdate}
        onCreate={onUpdate}
        options={options}
        ValueComponent={ValueComponent}
        OptionComponent={OptionComponent}
        alignOptions={{width: true}}
        extraClassName={extraClassName}
        listExtraClassName="tag-select-list"
    />}

function OptionComponent({className, option, ...props}) {
    const tags = React.useMemo(() => {
        if (!option.template) {return null}
        return JSON.parse(option.template.data).tags
    }, [option.template])
    if (!tags) {
        return <TagButton value={option.value} {...props} />
    }
    return <div className={className} role="button" {...props} >
        <div className="spacer spacer--p">
            <span className="flex1">{option.value}</span>
            <span className="spacer spacer--end">
                {tags.map(t => <TagButton value={t} key={t} />)}
            </span>
        </div>
    </div>
}
