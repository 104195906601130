import React from 'react'

import bem from '../utils/bem'
import AddButton from './add-button'
import Button from './button'
import CloseIcon from './pclose-icon'
import {OptionComponent} from './field-select'
import {ValueComponent} from './field-select'
import {QuickSelect} from './popup-select'
import Switcher from './switcher2'
import './sort-builder.scss'


export default function SortBuilder({value, fields, onUpdate}) {
    const className = bem("sort-builder")

    fields = React.useMemo(() => {
        console.log('fields', fields)
        return fields.filter(f => f.sortable !== false)
    }, [fields])

    function addField(field) {
        onUpdate(prev => {
            return [
                ...prev,
                {field: field || null, dir: 'ASC'},
            ]
        })
    }

    function updateFieldItem(index, update) {
        onUpdate(prev => {
            return prev.map((p, i) => {
                if (i !== index) {return p}
                return {...p, ...update}
            })
        })
    }

    function removeFilterItem(index) {
        onUpdate(prev => {
            return prev.filter((p, i) => i !== index)
        })
    }

    return <div className={className}>
        <div className={className.e('top')}>
            {value && value[0] ?
                <SortItem
                    item={value[0]}
                    fields={fields}
                    onUpdate={update => updateFieldItem(0, update)}
                    onRemove={0 !== 0 ? (() => removeFilterItem(0)) : undefined}
                />
                : null
            }
            <QuickSelect
                value={null}
                options={fields}
                onUpdate={addField}
                extraClassName={className.e('field')}
                ValueComponent={AddButton}
                OptionComponent={OptionComponent}
            />
        </div>
        {value && value.slice(1).map((item, index) => {
            index += 1
            return <SortItem
                key={index}
                item={item}
                fields={fields}
                onUpdate={update => updateFieldItem(index, update)}
                onRemove={index !== 0 ? (() => removeFilterItem(index)) : undefined}
            />
        })}
    </div>
}


function SortItem({item, fields, onUpdate, onRemove}) {
    const className = bem("sort-item")

    const def = fields.filter(f => f.value == item.field)[0]
    const Component = (def && def.SortComponent) || Dir

    return <div className={className}>
        <QuickSelect
            value={item.field}
            options={fields}
            onUpdate={update => onUpdate({field: update})}
            extraClassName={className.e('field')}
            ValueComponent={ValueComponent}
            OptionComponent={OptionComponent}
        />
        <Component item={item} onUpdate={onUpdate} className={className.e('operand')} />
        {onRemove ?
            <CloseIcon
                onClick={onRemove}
                role="button"
                className="icon-button icon-button--no-shadow"
                color="--primary"
            />
            : null
        }
    </div>
}

function Dir({item, onUpdate, className}) {
    return <Switcher
        value={item.dir}
        options={[{value: 'ASC', message: 'A-Z'}, {value: 'DESC', message: 'Z-A'}]}
        onSwitch={(_, dir) => {
            if (dir === null) {return}
            onUpdate({...item, dir})
        }}
    />
}
