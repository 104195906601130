import React from 'react'

import {makeRequest} from '../http'
import bm from '../utils/bm'
import useHash from '../utils/use-hash'
import AddingModalSheet from './adding-modal-sheet'
import Button from './button'
import CloseIcon from './close-icon'
import EditIcon from './edit-icon'
import Modal from './modal'
import ModalSheet from './modal-sheet'
import {ObjectContext} from './object-store'
import {ProducerEditorLine} from './producer-editor'
import {M} from './product-editor'
import {Resources} from './resource'
import Sheet from './sheet'
import './producer-view.scss'
import ProducersProductList from './producers-product-list'
import CloseModalButton from './close-modal-button'

const addingPlaceholder = `Product...
Product...`


export function ProducerHeader({
    producer,
    onClose,
    updateProducer,
    adding,
    setAdding,
    merging,
    setMerging,
    deleteProducer,
    selectProducer,
    handleMore,
    panTo,
    locating,
    setLocating,
    onAddProduct,
    spinning,
    onUploadResources,
}) {
    if (!producer) {return null}

    return <>
        <h1 className="producer-view__title">
            <span className="producer-view__title__text">
                {producer.name}
            </span>
            {onClose ?
                <CloseModalButton onClick={onClose} />
                : null
            }
        </h1>
        <ProducerData
            producer={producer}
            updateProducer={updateProducer}
            panTo={panTo}
        />
        <AddingModalSheet
            open={adding}
            onRequestClose={() => setAdding(false)}
            placeholder={addingPlaceholder}
            slot={1}
            onAdd={text => {
                return onAddProduct(producer, text)
            }}
        />
        {producer._status === 'TRASHED' ?
            <div className="producer-sheet__deleted-overlay">
                TRASHED
            </div>
            : null
        }
    </>
}


export function ProducerContent({
    producer, toggleProduct, selectedProducts, selectProduct, merging, limit, onShowProducts, productsIds, producerLink
}) {
    const products = React.useMemo(() => {
        if (producer && productsIds) {
            return producer.products.filter(id => productsIds.indexOf(id) !== -1)
        }
    }, [producer, productsIds])
    if (!producer) {return null}

    return <div className="producer-view__content">
        <Resources ids={producer.resources} model="producer" modelId={producer.id} padded />
        <ProducerNote producer={producer} />
        <ProducersProductList
            producer={producer}
            products={products}
            selectedProducts={selectedProducts}
            onSelectProduct={merging ? toggleProduct : undefined}
            onShowProduct={merging ? undefined : selectProduct}
            onShowProducts={onShowProducts}
            selectable={merging}
            bestDates
            limit={limit}
            card
        />
        {(products && producer && products.length < producer.products.length) ?
            <a href={producerLink} className="tp">{producer.products.length - products.length} more</a>
            : null
        }
    </div>
}


function ProducerNote({producer}) {
    if (!producer.note) {return null}
    const lines = producer.note.split('\n')
        .filter(s => !s.startsWith('- '))
    if (!lines || lines.length === 0) {
        return null
    }
    return <div className="producer-view__note-c">
        {
            lines.map((s, index) => <p key={index} className="producer-view__note">{s}</p>)
        }
    </div>
}

export function useProducerProps(props) {
    const [locating, setLocating] = React.useState(false)
    const [merging, setMerging] = React.useState(false)
    const [adding, setAdding] = React.useState(false)
    const [selectedProducts, setSelectedProducts] = React.useState([])
    const [spinning, setSpinning] = React.useState(false)

    React.useEffect(() => {
        if (!merging) {setSelectedProducts([])}
    }, [merging])

    function selectProduct(event, product) {
        props.setHash(window.location.hash.split('/').slice(0, 2).join('/') + '/' + product.id, props.params)
        props.setProductSheetPosition('open')
    }

    function toggleProduct(event, product) {
        setSelectedProducts(prev => {
            if (prev.indexOf(product._slug) === -1) {
                return [...prev, product._slug]
            }
            else {
                return prev.filter(i => i !== product._slug)
            }
        })
    }

    function handleMore(value) {
        const update = {id: props.producer.id}
        if (value === 'delete') {
            update._status = 'TRASHED'
        }
        else if (value === 'no-g') {
            update._status = 'NO-G'
        }
        setSpinning(true)
        props.updateProducer(update)
            .then(() => {
                setSpinning(false)
            })
            .catch(error => {
                setSpinning(false)
                throw error
            })
    }

    return {
        ...props,
        selectProducer: props.setSelectedProducer,
        onClose: () => {
            props.setProducerSheetPosition('closed')
            props.setProductSheetPosition('closed')
            setTimeout(() => props.setHash('', props.params), 200)
        },
        locating,
        setLocating,
        adding,
        setAdding,
        merging,
        setMerging,
        selectedProducts,
        setSelectedProducts,
        toggleProduct,
        selectProduct,
        handleMore,
        spinning,
        setSpinning,
    }
}


export function ProducerFooter({producer, merging, setMerging, selectedProducts, setSelectedProducts, updateProducer}) {
    selectedProducts ||= []

    return <BottomBar
        open={merging}
        message="Merge"
        onCancel={() => setMerging(false)}
        disabled={selectedProducts.length < 2}
        onConfirm={() => {
            const template = producer.products.filter(p => p._slug === selectedProducts[0] && p._name)[0]
            const toMerge = producer.products
                .filter(p => selectedProducts.indexOf(p._slug) !== -1)
                .map(p => {
                    return {
                        id: p.id,
                        slug: p.year + '-' + template._slug,
                        name: template._name + ' ' + p.year
                    }
                })
            setSelectedProducts([])
            makeRequest('2/update-products', {
                // signal: controller.signal,
                body: JSON.stringify(toMerge)
            })
                .then(response => {
                    const response_by_id = {}
                    response.forEach(r => response_by_id[r.id] = r)
                    setMerging(false)
                    updateProducer({
                        ...producer,
                        products: producer.products.map(p => {
                            if (response_by_id[p.id]) {
                                return response_by_id[p.id]
                            }
                            return p
                        })
                    })
                })
                .catch(error => {
                    console.error(error)
                    window.alert('ERROR', error)
                })
        }}
    >
    </BottomBar>
}

function BottomBar({open, disabled, onCancel, message, onConfirm}) {
    return <div className={bm('producer-view__bottom-bar', {open})}>
        <button className="button" onClick={onCancel}>Cancel</button>
        <button disabled={disabled} className="button" onClick={onConfirm}>{message}</button>
    </div>
}


export function ProducerData({producer, updateProducer, panTo, toggleMergeActive, done, children}) {
    const {setHashParam} = useHash()

    return <div className="producer-view__data">
        {producer.address ?
            <div className="producers-list__producer-info">{producer.address}</div>
            : null
        }
        <div className="producers-list__producer-buttons">
            <ObjectContext model="producer" id={producer.id}>
                <ProducerEditorLine
                    panTo={panTo}
                    toggleMergeActive={toggleMergeActive}
                    done={done}
                    prefix={
                        <Button onClick={() => setHashParam('edit','producer/' + producer.id)}>
                            <EditIcon color="white" />
                        </Button>
                    }
                />
            </ObjectContext>
        </div>
    </div>
}


