import React from 'react'
import TextareaAutosize from 'react-textarea-autosize'

import {Rating} from '../page-products-notes'
import bem from '../utils/bem'
import useHash from '../utils/use-hash'
import Modal from './modal'
import {ObjectContext} from './object-store'
import {useField} from './object-store'
import {useFocusBlurSaveField} from './object-store'
import {useGet} from './object-store'
import {useSaveField} from './object-store'
import Page from './page'
import ProducerSelect from './producer-select'
import ProductEditor from './product-editor'
import {ProductEditorLine} from './product-editor'
import {Resources} from './resource'
import CloseModalButton from './close-modal-button'
import './q-product.scss'
import FileInputButton from './file-input-button'


export default function QProduct() {
    const {params, setHashParam} = useHash()
    const id = params.id
    const product = useGet('product', id)

    if (!id) {return <div className="p404">404</div>}

    return <ObjectContext model="product" id={id}>
        <QuickProductEditor key={id} id={id} product={product} />
    </ObjectContext>
}



function QuickProductEditor({id, product, onClose}) {
    const className = bem('editor')
    const nameProps = useFocusBlurSaveField('name')
    const noteProps = useFocusBlurSaveField('note')
    const [resources] = useField('resources')
    const [producerId, setProducerId] = useSaveField('producer_id')

    React.useEffect(() => console.log('nameProps', nameProps), [nameProps])
    React.useEffect(() => console.log('noteProps', noteProps), [noteProps])

    return <div className="p product-editor w100">
        <ProducerSelect
            value={producerId}
            onUpdate={setProducerId}
            alignOptions="match-width"
        />
        <div className={className.e('i')}>
            <span className={className.e('i').e('m')}>
                <input type="text" {...nameProps} placeholder="name" />
            </span>
        </div>
        <ProductEditorLine product={product} />
        <Resources ids={resources} model="product" />

        <FileInputButton
            multiple={false}
            model="product"
            modelId={id}
            message="Add photo..."
        />

        <TextareaAutosize
            {...noteProps}
            placeholder="Note..."
        />
        <div className="spacer">
            <Rating source="AG" productId={id} />
            <Rating source="PW" productId={id} />
        </div>
    </div>
}
