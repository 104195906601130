import React from 'react'
import {LazyLoadImage} from 'react-lazy-load-image-component'

import useUpdatingRef from '../hooks/use-updating-ref'
import bem from '../utils/bem'
import {calculateRankAfter} from '../utils/calculate-rank'
import {calculateRankBefore} from '../utils/calculate-rank'
import {calculateRankBetween} from '../utils/calculate-rank'
import {calculateRankForSortedArray} from '../utils/calculate-rank'
import useHash from '../utils/use-hash'
import Button from './button'
import get from './get'
import CloseModalButton from './close-modal-button'
import DeleteIcon from './icons/delete-icon'
import MoveToEndIcon from './icons/move-to-end-icon'
import MoveToLeftIcon from './icons/move-to-left-icon'
import MoveToRightIcon from './icons/move-to-right-icon'
import MoveToStartIcon from './icons/move-to-start-icon'
import Rotate180Icon from './icons/rotate-180-icon'
import RotateCcwIcon from './icons/rotate-ccw-icon'
import RotateCwIcon from './icons/rotate-cw-icon'
import Modal from './modal'
import {useDelete} from './object-store'
import {useObjectStore} from './object-store'
import {useObject} from './object-store'
import {useSave} from './object-store'
import sorted from './sorted'
import './resource.scss'


function Resource({id, onOpen, extraClassName, preventDefault, suffix, ...props}) {
    const resource = useObject(id)
    if (!resource) {return null}

    function handleClick(event) {
        if (onOpen) {
            window.mapkiModalTarget = event.target
            onOpen(resource, event)
            if (preventDefault !== false) {
                event.preventDefault()
            }
        }
    }

    if (resource.file) {
        const src = URL.createObjectURL(resource.file)
        return <a href={src} target="_blank" className={bem("resource").x(extraClassName)} onClick={handleClick} role="button">
            {/*<div className="yo">{resource.rank}</div>*/}
            <img src={src} />
        </a>

    }

    if (resource.url) {
        return <a href={resource.url} target="_blank" className={bem("resource").x(extraClassName)} onClick={handleClick} role="button" {...props}>
            <LazyLoadImage
                src={resource.url + (suffix ? '?' + suffix : '')}
            />
        </a>
    }
    return null
}


function MainResource({id, children}) {
    const resource = useObject(id)
    if (!resource) {return null}
    return <a href={resource.url} className="resource__m" target="_blank">
        <img src={resource.url}  className="resource__m__image" />
        {children}
    </a>
}

const MemoResource = React.memo(Resource)


export function Resources({ids, padded}) {
    const {state} = useObjectStore()

    const {hash, setHashParam} = useHash()
    const [props, containerRef] = useResourceDnD(handleDrop)
    const save = useSave()

    const idsByRank = React.useMemo(() => {
        if (!ids) {return ids}
        return sorted(
            ids.map(id => get(state, id)),
            r => r.rank,
        )
            .filter(r => r)
            .map(r => r.id)
    }, [ids])
    const idsByRankRef = useUpdatingRef(idsByRank)

    const handleOpen = React.useCallback(function handleOpen(resource) {
        const idsByRank = idsByRankRef.current
        const o = idsByRank
            .map(id => {
                if (id == '*' + resource.id) {
                    return id
                }
                if (id == resource.id) {
                    return '*' + id
                }
                return id
            })
            .join(',')
        setHashParam('resources', o)
    }, [])

    if (!ids || ids.length === 0) {return null}


    function handleDrop(dragged, target) {
        let previous, next;
        if (target.side === 'right') {
            previous = state[target.targetId]
            next = state[idsByRank[idsByRank.indexOf(target.targetId) + 1]]
        }
        else {
            next = state[target.targetId]
            previous = state[idsByRank[idsByRank.indexOf(target.targetId) - 1]]
        }
        const rank = calculateRankBetween(get(previous, 'rank'), get(next, 'rank'))
        save('resource', {id: dragged, rank})
    }

    return <div className={bem("resources").m({padded})} ref={containerRef}>
        {idsByRank.map((id, index) => {
            return <MemoResource id={id} key={id} onOpen={handleOpen}
                {...props}
                data-id={id}
                data-index={index}
            />
        })}
    </div>
}

function useResourceDnD(onDrop) {
    const containerRef = React.useRef()
    const draggedRef = React.useRef()
    const targetRef = React.useRef()
    const sideRef = React.useRef()

    const onDragStart = React.useCallback(function onDragStart(event) {
        draggedRef.current = event.target
        event.__mapki_handled = true
    }, [])

    const onDragEnter = React.useCallback(function onDragEnter(event) {
        targetRef.current = event.target
    }, [])

    const onDrag = React.useCallback(function onDrag(event) {
        const target = targetRef.current
        if (!target) {return}
        const parent = containerRef.current;
        const targetId = target.getAttribute('data-id')
        const draggedId = draggedRef.current.getAttribute('data-id')

        if (targetId == draggedId) {return}


        if (getSideOfElement(target, event.clientX) == 'right') {
            sideRef.current = {side: 'right', targetId}
            insertAfter(draggedRef.current, target);
        } else {
            sideRef.current = {side: 'left', targetId}
            insertBefore(draggedRef.current, target);
        }

        event.__mapki_handled = true;

        function insertAfter(newNode, existingNode) {
            parent.insertBefore(newNode, existingNode.nextSibling);
        }

        function insertBefore(newNode, existingNode) {
            parent.insertBefore(newNode, existingNode)
        }

        function getSideOfElement(element, clientX) {
            const elementRect = element.getBoundingClientRect()
            const elementCenter = elementRect.left + (elementRect.width / 2)

            if (clientX < elementCenter) {
                return 'left'
            } else {
                return 'right'
            }
        }
    }, [])

    const onDragEnd = React.useCallback(function onDragEnd(event) {
        if (!targetRef.current) {return}
        onDrop(draggedRef.current.getAttribute('data-id'), sideRef.current)
        targetRef.current = null
        draggedRef.current = null
    }, [])

    const props = {
        draggable: true,
        onDragStart,
        onDragEnter,
        onDrag,
        onDragEnd,
    }
    return [props, containerRef]
}


export function ResourcesModal() {
    const {params, setHashParam} = useHash()
    const ids = params.resources ? params.resources.split(',').map(id => id.startsWith('*') ? id.slice(1) : id) : null
    const main = params.resources && (params.resources
        .split(',')
        .find(s => s.startsWith('*')) || ''
    ).slice(1)
    const [props, dnd] = useReasourceEditing(main, ids)
    const paramsRef = useUpdatingRef(params)

    const handleOpen = React.useCallback(function handleOpen(resourceId) {
        const params = paramsRef.current
        const o = params.resources
            .split(',')
            .map(id => {
                if (id == '*' + resourceId) {
                    return id
                }
                if (id == resourceId) {
                    return '*' + id
                }
                if (id.startsWith('*')) {
                    return id.slice(1)
                }
                return id
            })
            .join(',')
        setHashParam('resources', o)
    }, [])

    const handleClose = React.useCallback(function handleClose(event) {
        if (event.target.classList.contains('resources-modal')) {
            setHashParam('resources', null)
        }
    }, [])

    if (!params.resources) {
        return null
    }


    return <Modal open onRequestClose={() => setHashParam('resources', null)} transparent>
        <div className="resources-modal" onClick={handleClose}>
            <div className="resources-modal__inner">
                <CloseModalButton
                    onClick={() => setHashParam('resources', null)}
                    extraClassName="resources-modal__close-button"
                />
                {main ?
                    <MainResource
                        id={main}
                    >
                    </MainResource>
                    : null
                }
                {main ?
                    <ResourceButtons id={main}
                        onOpen={handleOpen}
                        onClose={handleClose}
                        {...props}
                    />
                    : null
                }
            </div>
            <div className="resources-modal__previews" ref={dnd[1]} onDragOver={e => e.preventDefault()}>
                {ids.map((id, index) => {
                    return <Preview
                        key={id}
                        id={id}
                        onOpen={handleOpen}
                        data-id={id}
                        data-index={index}
                        {...dnd[0]}
                    />
                })}
            </div>
        </div>
    </Modal>
}


function useReasourceEditing(main, ids) {
    const deleteObject = useDelete()
    const save = useSave()
    const {state} = useObjectStore()
    const [rotating, setRotating] = React.useState({})
    const {params, setHashParam} = useHash()

    function onDrop(dragged, target) {
        const resources = params.resources.split(',')
            .map(id => id.startsWith('*') ? id.slice(1) : id)
            .map(id => get(state, id))
            .filter(r => r)

        const idsByRank = sorted(
            ids.map(id => get(state, id)),
            r => r.rank,
        )
            .filter(r => r)
            .map(r => r.id)

        let previous, next;
        if (target.side === 'right') {
            previous = state[target.targetId]
            next = state[idsByRank[idsByRank.indexOf(target.targetId) + 1]]
        }
        else {
            next = state[target.targetId]
            previous = state[idsByRank[idsByRank.indexOf(target.targetId) - 1]]
        }
        const rank = calculateRankBetween(get(previous, 'rank'), get(next, 'rank'))
        save('resource', {id: dragged, rank})
        const o = sorted(
            resources.map(r => r.id == dragged ? {...r, rank}: r),
            r => r.rank
        )
            .map(r => main == r.id ? '*' + r.id : r.id)
            .join(',')
        setHashParam('resources', o)
    }

    const dnd = useResourceDnD(onDrop)

    function handleDelete(resourceId) {
        const o = params.resources
            .split(',')
            .filter(id => {
                if (id == resourceId || id == '*' + resourceId) {
                    return false
                }
                return true
            })
            .join(',')
        deleteObject('resource', resourceId)
        setHashParam('resources', o)
    }

    function handleMoveToStart(resourceId) {
        resourceId ||= main
        let firstResourceId = params.resources.split(',')[0]
        if (firstResourceId.startsWith('*')) {
            firstResourceId = firstResourceId.slice(1)
        }
        const firstRank = state[firstResourceId].rank
        const thisRank = state[resourceId].rank
        if (firstRank === thisRank) {
            return
        }
        const nextRank = calculateRankBefore(firstRank)
        save('resource', {id: resourceId, rank: nextRank})

        const o = '*' + resourceId + ',' + params.resources
            .split(',')
            .filter(id => id != resourceId && id != '*' + resourceId)
            .join(',')
        setHashParam('resources', o)
    }

    function handleMoveToEnd(resourceId) {
        resourceId ||= main
        let lastResourceId = params.resources.split(',').pop()
        if (lastResourceId.startsWith('*')) {
            lastResourceId = lastResourceId.slice(1)
        }
        const lastRank = state[lastResourceId].rank
        const thisRank = state[resourceId].rank
        if (lastRank === thisRank) {
            return
        }
        const nextRank = calculateRankAfter(lastRank)
        save('resource', {id: resourceId, rank: nextRank})

        const o = params.resources
            .split(',')
            .filter(id => id != resourceId && id != '*' + resourceId)
            .join(',') + ',*' + resourceId
        setHashParam('resources', o)
    }

    function handleMoveToLeft(resourceId) {
        resourceId ||= main
        const resources = params.resources.split(',')
            .map(id => id.startsWith('*') ? id.slice(1) : id)
            .map(id => get(state, id))
            .filter(r => r)
        const index = resources.map(r => r.id).indexOf(resourceId)
        const nextIndex = index - 1
        const nextRank = calculateRankForSortedArray(resources, nextIndex)
        save('resource', {id: resourceId, rank: nextRank})
        const o = sorted(
            resources.map(r => r.id == resourceId ? {...r, rank: nextRank}: r),
            r => r.rank
        )
            .map(r => resourceId == r.id ? '*' + r.id : r.id)
            .join(',')
        setHashParam('resources', o)
    }

    function handleMoveToRight(resourceId) {
        resourceId ||= main
        const resources = params.resources.split(',')
            .map(id => id.startsWith('*') ? id.slice(1) : id)
            .map(id => get(state, id))
            .filter(r => r)
        const index = resources.map(r => r.id).indexOf(resourceId)
        const nextIndex = index + 2
        const nextRank = calculateRankForSortedArray(resources, nextIndex)
        save('resource', {id: resourceId, rank: nextRank})
        const o = sorted(
            resources.map(r => r.id == resourceId ? {...r, rank: nextRank}: r),
            r => r.rank
        )
            .map(r => resourceId == r.id ? '*' + r.id : r.id)
            .join(',')
        setHashParam('resources', o)
    }

    function handleRotate(resourceId, degrees) {
        setRotating(prev => ({...prev, [resourceId]: true}))
        save('resource', {id: resourceId, degrees})
            .then(() => setRotating(prev => {
                const next = {...prev}
                delete next[resourceId]
                return next
            }))
            .catch(() => setRotating(prev => {
                const next = {...prev}
                delete next[resourceId]
                return next
            }))
    }

    const props = {
        onDelete: handleDelete,
        onMoveToStart: handleMoveToStart,
        onMoveToEnd: handleMoveToEnd,
        onMoveToLeft: handleMoveToLeft,
        onMoveToRight: handleMoveToRight,
        onRotate: handleRotate,
        rotating: rotating,
    }

    return [props, dnd]
}


function Preview({id, onOpen, ...props}) {
    return <MemoResource
        id={id}
        extraClassName={bem("resources-modal__preview")}
        onOpen={() => onOpen(id)}
        suffix="yo"
        {...props}
    />
}



function ResourceButtons({id, rotating, onMoveToStart, onMoveToLeft, onMoveToRight, onMoveToEnd, onDelete, onRotate}) {
    return <div className="resource-buttons">
        <div className="spacer">
            <Button onClick={() => onMoveToStart(id)}>
                <MoveToStartIcon color="white" />
            </Button>
            <Button onClick={() => onMoveToLeft(id)}>
                <MoveToLeftIcon color="white" />
            </Button>
            <Button onClick={() => onMoveToRight(id)}>
                <MoveToRightIcon color="white" />
            </Button>
            <Button onClick={() => onMoveToEnd(id)}>
                <MoveToEndIcon color="white" />
            </Button>
            <div style={{width: '2rem'}}/>
            <Button onClick={() => onRotate(id, 90)} disabled={rotating[id]}>
                <RotateCcwIcon color="white"/>
            </Button>
            <Button onClick={() => onRotate(id, 180)} disabled={rotating[id]}>
                <Rotate180Icon color="white"/>
            </Button>
            <Button onClick={() => onRotate(id, 270)} disabled={rotating[id]}>
                <RotateCwIcon color="white"/>
            </Button>
        </div>
        <Button onClick={() => onDelete(id)}>
            <DeleteIcon color="white" />
        </Button>
    </div>
}

function Spacer({grow}) {
    return <span className={bem("resource-buttons__spacer").m({grow})} />
}
