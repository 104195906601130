import React from 'react'

import {makeRequest} from '../http'
import LoginPage from '../page-login'
import useHash from '../utils/use-hash'
import {useUpdate} from './object-store'
import {useSaveListener} from './object-store'
import sorted from './sorted'
import './init-context.scss'


export const InitContext = React.memo(InitContext_)

const interestedInSaved = {
    models: {saved_query: true, product_tag: true, producer_tag: true, template: true}
}

const InitContextProvider = React.createContext();


function InitContext_({children}) {
    const {hash, setHash} = useHash()
    const update = useUpdate()

    const [init, setInit] = React.useState(null)
    const [error, setError] = React.useState(null)
    const firstLoadRef = React.useRef(true)

    const updateInit = React.useCallback((...args) => {
        // refresh()
    }, [])


    useSaveListener(interestedInSaved, updateInit)

    React.useEffect(() => {
        if (firstLoadRef.current) {
            refresh()
            firstLoadRef.current = false
        }
        else if (hash.indexOf('gurl=') !== -1) {
            const prev = hash
            setHash('producers')
            setInit(null)
            refresh(prev)
        }
    }, [hash])

    function refresh(hash) {
        makeRequest('2/init', {
            body: JSON.stringify({hash: hash || window.location.hash})
        })
            .then(response => {
                update(response.objects)
                update(response.saved_query)
                update(response.template)
                const init = response
                init.producer_tag_options = buildTagOptions(response.producer_tag)
                init.product_tag_options = buildTagOptions(response.product_tag)
                if (init.redirect) {
                    setHash(init.redirect)
                }
                if (init.close) {
                    setHash(init.close)
                    setTimeout(() => {
                        window.close()
                    }, 1000)
                }
                setInit(init)
            })
            .catch(error => {
                window.alert(error)
                setError(error)
            })
    }

    if (hash.startsWith('#login')) {
        return <div className="init-context">
            <LoginPage />
        </div>
    }

    if (error) {
        return <div className="init-context">
            <div className="error-page">
                <h1>ERROR</h1>
                <p>
                    <a href="#login" className="a--fresh">Login</a>
                </p>
            </div>
        </div>
    }


    if (!init) {
        return <div className="init-context">
            <span className="spinner spinner--size-1000 spinner--primary" />
        </div>
    }


    return <InitContextProvider.Provider value={{init, setInit}}>
        {children}
    </InitContextProvider.Provider>
}


export function useInit() {
    return React.useContext(InitContextProvider).init
}

export function useSetInit() {
    return React.useContext(InitContextProvider).setInit
}

function buildTagOptions(tags) {
    const options = []
    Object.keys(tags).forEach(tag => {
        options.push({value: tag, count: tags[tag]})
    })
    return sorted(options, o => o.count, -1)
}
