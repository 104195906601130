import React from 'react'

import genId from '../utils/gen_id'
import Button from './button'
import ImageIcon from './image-icon'
import {useSave} from './object-store'
import {useUpdate} from './object-store'
import {useSpinning} from './spinning-context'


export default function FileInputButton({disabled, model, modelId, xxl, message, multiple, w100, extraClassName, onClick, onRef}) {
    const ref = React.useRef()
    const save = useSave()
    const update = useUpdate()

    function handleFileSelect(event) {
        modelId ||= genId()
        const resources = Array.from(event.target.files).map(file => {
            return {
                id: genId(),
                model,
                model_id: modelId,
                file,
            }
        })
        const promise = Promise.all([
            ...resources.map(r => save('resource', r)),
            update([{id: modelId, resources: prev => [...resources.map(r => r.id), ...(prev || [])]}])
        ])
        if (onClick) {
            promise.then(() => {
                onClick(event, {model, modelId, resources})
            })
        }
    }

    return <Button
        w100={w100}
        xxl
        disabled={disabled}
        onClick={() => {
            ref.current.click()
            extraClassName={extraClassName}
        }}
    >
        <ImageIcon width="16px" height="16px" color="white" />
        {message || null}
        <input
            ref={ref}
            style={{display: 'none'}}
            type="file"
            onChange={handleFileSelect}
            multiple={multiple !== false}
        />
    </Button>
}
