import React from 'react'

import Button from './button'
import Close2Icon from './icons/close2-icon'
import './close-modal-button.scss'


export default function CloseModalButton(props) {
    return <Button {...props} extraClassName={"close-modal-button " + props.extraClassName}>
        <Close2Icon color="--on-content" />
    </Button>
}
