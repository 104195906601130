import React from 'react'
import ReactDOM from 'react-dom'

// import './portal.scss'


export default function Portal({children, innerRef, parent, blocks, extraClassName}) {
    // parent - it should be an element that opens the portal
    // it's necessary to correctly handle "click outside"
    const element = React.useMemo(() => {
        const element = document.createElement('div')
        if (innerRef) {
            innerRef.current = element
        }
        if (typeof innerRef === 'function') {
            innerRef(element)
        }
        element.classList.add('portal')
        return element
    }, [])

    React.useEffect(() => {
        element.__portalParent = parent
    }, [parent])

    React.useEffect(() => {
        if (element) {
            document.body.appendChild(element)
            return () => document.body.removeChild(element)
        }
    }, [element])

    React.useEffect(() => {
        if (extraClassName) {
            element.classList.add(extraClassName)
            return element.classList.remove(extraClassName)
        }
    }, [extraClassName])

    React.useEffect(() => {
        if (!blocks) {return}
        document.body.classList.add('portal-open')
        return () => {
            document.body.classList.remove('portal-open')
        }
    }, [blocks])

    return ReactDOM.createPortal(children, element)
}