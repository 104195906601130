import React from 'react'

import Svg from './svg'


export default function CloseIcon({width, height, color, ...props}) {
    color = getComputedStyle(document.documentElement)
        .getPropertyValue(color || '--content-color') || color
    return <Svg
        width={width || '16px'} height={height || '16px'} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="pclose" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M50,82 C32.326888,82 18,67.673112 18,50 C18,32.326888 32.326888,18 50,18 C67.673112,18 82,32.326888 82,50 C82,67.673112 67.673112,82 50,82 Z M50.32,47.28471 L36.969824,33.9345339 L34.2545339,36.649824 L47.60471,50 L34.2545339,63.350176 L36.969824,66.0654661 L50.32,52.71529 L63.670176,66.0654661 L66.3854661,63.350176 L53.03529,50 L66.3854661,36.649824 L63.670176,33.9345339 L50.32,47.28471 Z" id="Shape" fill={color}></path>
        </g>
    </Svg>
}
