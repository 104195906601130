import React from 'react'

import useDropResource from '../hooks/use-drop-resource'
import bem from '../utils/bem'
import useHash from '../utils/use-hash'
import Button from './button'
import CloseIcon from './close-icon'
import Modal from './modal'
import {useGet} from './object-store'
import {useObject} from './object-store'
import ProducerEditor from './producer-editor'
import ProductEditor from './product-editor'
import {useWantHaveDoneWorkflow} from './product-editor'
import {ProductsEditor} from './products-editor-modal'
import {useSpinning} from './spinning-context'
import CloseModalButton from './close-modal-button'
import './producer-editor-modal.scss'


export default function ProducerEditorModal() {
    const [query, setQuery] = React.useState('')
    const {params, setHashParam} = useHash()
    const producerId = (params.edit && params.edit.startsWith('producer/')) ?
        params.edit.split('producer/')[1].split('/')[0]
        : null
    const productId = getProductId(params.edit)
    const productsIds = getProductsIds(params.edit)

    useGet('producer', producerId)
    const producer = useObject(producerId)
    useGet('product', productId)
    const product = useObject(productId)

    const {error} = useSpinning()


    function close() {
        setHashParam('edit', null)
    }

    const className = bem('producer-editor-modal')

    const {increaseDone} = useWantHaveDoneWorkflow({productId: productId})
    const {dropProps} = useDropResource({
        model: productId ? 'product' : 'producer',
        modelId: productId || producerId,
        parentClassName: className,
        onDrop: productId ?
            (e) => {
                if (e.shiftKey) {
                    increaseDone()
                }
            }
            : null
    })

    function resetQuery() {
        if (params.edit.indexOf('/product') === -1) {
            setQuery('')
        }
    }

    if (!producerId) {return null}

    return <Modal
        open={params.edit}
        onRequestClose={close}
        innerExtraClassName={className}
        {...dropProps}
    >
        <div className={className.e('top-bar')}>
            <h2>
                {producer ?
                    <B p={producer} url={`producer/${producer.id}`} setHashParam={setHashParam} onClick={resetQuery}/>
                    : null
                }
                {producer && product ?
                    <B p={product} url={`producer/${producer.id}/product/${product.id}`} setHashParam={setHashParam} onClick={resetQuery}/>
                    : null
                }
                {productsIds ?
                    <PsB id={productsIds[0]} productsIds={productsIds} setHashParam={setHashParam} onClick={resetQuery} />
                    : null
                }
            </h2>
            <span className={className.e('top-bar-buttons')}>
                <CloseModalButton onClick={close} />
            </span>
        </div>
        <div className={className.e('content').m({product: !!product})}>
            {producer ?
                <ProducerEditor
                    producer={producer}
                    setHashParam={setHashParam}
                    query={query}
                    setQuery={setQuery}
                />
                : null
            }
            {product ?
                <ProductEditor
                    product={product}
                />
                : null
            }
            {productsIds ?
                <ProductsEditor
                    productsIds={productsIds}
                />
                : null
            }
        </div>
    </Modal>
}

function B({p, url, setHashParam, _name, onClick}) {
    if (!p) {return null}
    return <span className="producer-editor-modal__b"
        onClick={() => {
            setHashParam('edit', url)
            onClick()
        }}>
        {_name? p._name : p.name}
    </span>
}


function getProductId(h) {
    if (!h) {return null}
    const s = h.split('product/')
    if (s[1]) {
        return s[1].split('/')[0]
    }
    return null
}


function getProductsIds(h) {
    if (!h) {return null}
    const s = h.split('products/')
    if (s[1]) {
        return s[1].split('/')[0].split(',')
    }
    return null
}

function PsB({id, productsIds, setHashParam, onClick}) {
    const product = useObject(id)
    return <B p={product} url={`producer/${product.producer_id}/products/${productsIds.join(',')}`} setHashParam={setHashParam} _name onClick={onClick} />
}
